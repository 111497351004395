import { useQuery } from "react-query"
import useSalonId from "./useSalonId"
import servicesAPI from "actions/services/api"
import { message } from "antd"

export const useSalonServices = () => {
    const salonId = useSalonId()
    const { isLoading, isError, data: salonsServices } = useQuery(["services", salonId], () => servicesAPI.getAll({ salonId }), {
        refetchOnMount: true,
        refetchOnReconnect: false,
        refetchOnWindowFocus: true,
        onError: () => {
            message.error("Une erreur est survenu lors du chargement de vos services")
        }
    })

    return { isLoading, isError, salonsServices }
}