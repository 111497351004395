import { Container, StatusTitle } from 'components/Appointment/Navigation/styles'
import { useCaisseCurrentFilter, useCaisseCurrentStatus } from 'contexts/Caisse'
import { caisseActions } from 'contexts/Caisse/reducer'
import { caisseOptions } from 'enums/constants'
import React, { useCallback, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { createAction } from 'utils'



const StatusNavigation = () => {
    const { filterCaisse } = useCaisseCurrentFilter()
    const { currentStatus } = useCaisseCurrentStatus()
    const [searchParams, setSearchParams] = useSearchParams()

    const setCaisseStatus = useCallback((id) => {
        // Creation de l'action reducer
        const action = createAction(caisseActions.set_status, id)        
        // Mettre a jour l'url params
        searchParams.set("type", id)
        setSearchParams(searchParams) // ex: ?type=MOUVEL+ENCAISSEMENT
        // Switcher vers un autre tab
        filterCaisse(action)
    }, [filterCaisse])
    
    useEffect(() => {
        let initialStatus = searchParams.get("type");
        
        if (!initialStatus) {
            initialStatus = "NOUVEL ENCAISSEMENT"; // Set default status
            searchParams.set("type", initialStatus);
            setSearchParams(searchParams);
        }

        const action = createAction(caisseActions.set_status, initialStatus);
        // Au chargment de la page, on switch vers le tab par defaut en fonction de l'url
        filterCaisse(action);
    }, [filterCaisse, searchParams, setSearchParams]);

    return (
        <>
            <Container>
                {caisseOptions.map((status) => {
                    return (
                        <StatusTitle
                            key={status.id}
                            onClick={() => setCaisseStatus(status.id)}
                            active={status.id === currentStatus}
                            level={4}>
                            {status.title}
                        </StatusTitle>
                    )
                })}
            </Container>
        </>
    )
}

export default StatusNavigation