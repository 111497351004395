import moment from 'moment'
import React from 'react'
import { Space } from 'antd'

import * as Styled from "../styles"
import { DATE_FILTER_FORMATS, OPTIONS_PERIOD_FILTER } from 'components/Dashboard/DashboardFilter/utils'
import { Option, SelectContainer } from 'components/SelectDropDown'
import DatePicker from "components/DatePicker"

const TransactionFilterPeriod = () => {
    const defaultValue = ""
    const [picker, setPicker] = React.useState(defaultValue)

    const changePeriod = (value) => {
        setPicker(value)
        // return dispatchStatistiques({
        //     type: STATISTIQUE_REDUCER_ACTION_TYPE.CHANGE_PERIOD,
        //     payload: value
        // })
    }
    const changeDate = (value) => {
        // return dispatchStatistiques({
        //     type: STATISTIQUE_REDUCER_ACTION_TYPE.CHANGE_DATE,
        //     payload: moment(value).format("DD-MM-YYYY")
        // })
    }
    return (
        <Styled.FilterPeriodContainerStyled>
            <Space style={{ width: "100%" }} size='middle'>
                <DatePicker
                    allowClear={false}
                    defaultValue={moment()}
                    format={DATE_FILTER_FORMATS(new Date())}
                    picker={picker}
                    disabledDate={null}
                    onChange={(val) => changeDate(val)}
                />
                <SelectContainer
                    style={{ width: "150px" }}
                    defaultValue={defaultValue}
                    onChange={(val) => changePeriod(val)}
                    placeholder='Sort Type'>
                    {OPTIONS_PERIOD_FILTER.map((val) => (
                        <Option key={val.key} value={val.key}>{val.text}</Option>
                    ))}
                </SelectContainer>
            </Space>
        </Styled.FilterPeriodContainerStyled>
    )
}

export default TransactionFilterPeriod