import React from "react"
import * as Styled from "./styles"
import useSalonCurrency from "hooks/useSalonCurrency"
import { comparisonText } from "enums/constants"
import { Context } from "contexts/Dashboard"
import Header from "components/HeaderWithIcon"
import { getPercentegeDiff } from "utils"
const Turnover = ({ earning: data }) => {
  const currency = useSalonCurrency()
  const [state] = React.useContext(Context)
  return (
    <Styled.Container id='dashboard-turnover'>
      <Header>CHIFFRE D'AFFAIRE</Header>
      <Styled.Content>
        <Styled.Flex>
          <Styled.Revenue>
            <Styled.Title level={1}>{data.current}</Styled.Title>
            <Styled.SubTitle>{currency}</Styled.SubTitle>
          </Styled.Revenue>
        </Styled.Flex>
        <Styled.ComparisonValue>
          {+data.current > +data.prev ? "+" : "-"}&nbsp;
          {getPercentegeDiff(data.current, data.prev)}%&nbsp;
          {comparisonText[state.dashboardPeriod]}
        </Styled.ComparisonValue>
        <Styled.Actions>
          <Styled.Tax color='var(--elma-primary)'>100% ESPECE</Styled.Tax>
          <Styled.Tax color='#FF671A'>0% CB</Styled.Tax>
        </Styled.Actions>
      </Styled.Content>
    </Styled.Container>
  )
}

export default Turnover
