import React from 'react'
import * as Styled from "../styles"
import useSalonCurrency from 'hooks/useSalonCurrency'
import { useServicesContext } from 'contexts/Services/ServicesProvider'
import Button from 'components/Button'
import { Delete, Trash, Trash2 } from 'react-feather'
import { Col, Row } from 'antd'
import { useNavigate } from 'react-router'
import { getCaissePaiemenVenteRapidePath } from 'routes/utils'
import useSalonId from 'hooks/useSalonId'
import { SERVICES_REDUCER_ACTION_TYPE } from 'contexts/Services/reducer'

const PanierAction = () => {
    const currency = useSalonCurrency()
    const { servicesState, dispatchServices } = useServicesContext()
    const navigate = useNavigate()
    const salonId = useSalonId()

    const resetPanier = () => {
        dispatchServices({
            type: SERVICES_REDUCER_ACTION_TYPE.RESET_VENTE_RAPIDE_SETVICES
        })
    }

    return (
        <div>
            <Styled.FwTotalPrice>{servicesState.totalPrixInPanier} {currency}</Styled.FwTotalPrice>
            <Row gutter={10}>
                <Col>
                    <Button
                        onClick={resetPanier}
                        style={{ backgroundColor: "var(--elma-danger)" }}
                    // onClick={() => {
                    //     dispatchServices({
                    //         type: SERVICES_REDUCER_ACTION_TYPE.SELECT_SERVICE,
                    //         payload: servicesState.selectedService.id
                    //     })
                    //     handleOk()
                    // }}
                    >
                        <Trash2 />
                    </Button>
                </Col>
                <Col>
                    <Button
                        onClick={() => navigate(getCaissePaiemenVenteRapidePath(salonId))}
                        style={{ backgroundColor: "var(--elma-primary)" }}
                    // onClick={() => {
                    //     dispatchServices({
                    //         type: SERVICES_REDUCER_ACTION_TYPE.SELECT_SERVICE,
                    //         payload: servicesState.selectedService.id
                    //     })
                    //     handleOk()
                    // }}
                    >
                        Sauvegarder
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default PanierAction