import React from "react"
import { useQueryClient } from "react-query"
import useSalonId from "./useSalonId"
import employeesAPI from "actions/employees/api"

const useEmployeesPrefetch = () => {
  const salonId = useSalonId()
  const queryClient = useQueryClient()

  React.useEffect(() => {
    if (!salonId) return

    const prefetchEmployees = () => {
      queryClient.prefetchQuery("employees", () => employeesAPI.getAll(salonId))
    }

    // we only prefetch if we are already on the exact route
    prefetchEmployees()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useEmployeesPrefetch
