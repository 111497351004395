import styled, { css } from "styled-components"
import { elmaBreakpoints } from "styles/theme"

import { Row } from "antd"

const marginSolve = () => {
  let styles = ""
  const cols = 6
  for (let i = 0; i <= cols; i++) {
    styles += `
      div:nth-of-type(2n - ${cols}){
        padding-right:0 !important;
        margin-left:4px !important;
      }
      `
  }
  return css`
    ${styles}
  `
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const EditedRow = styled(Row)`
  justify-content: ${({ empty }) => (empty ? "center" : "unset")};
  @media screen and (min-width: ${elmaBreakpoints.md}px) {
    ${marginSolve()};
    .ant-col {
      margin-left: -4px;
    }
  }
`

export const Span = styled.span`
  @media screen and (max-width: ${elmaBreakpoints.sm}px) {
    display: none !important;
  }
`
