import styled from "styled-components"
import { Form, Select, Button, Col } from "antd"
import { elmaBreakpoints } from "styles/theme"
export const Container = styled.div`
  padding: 10px 20px;
`
export const Error = styled.div`
  position: absolute;
  right: 5%;
  transform: translatey(-50%);
  z-index: 10;
  top: 52%;
  cursor: pointer;
`
export const ActionCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  @media screen and (max-width: ${elmaBreakpoints.md}px) {
    justify-content: flex-start;
    .ant-row {
      width: 100%;
      .ant-col,
      .ant-form-item-control-input,
      .ant-form-item-control-input-content,
      .ant-space,
      .ant-space-item,
      button {
        width: inherit;
      }
    }
  }
`
export const StyledSelect = styled(Select)`
  .ant-select-selection {
    background-color: var(--elma-primary);
    color: #fff;
  }
  .ant-select-selector {
    display: flex;
    align-items: center;
    border-width: 2px !important;
    height: 60px !important;
    padding: 1.2em 1.5em !important;
    border-radius: 8px !important;
    border-color: #eaeaea !important;
  }
`
export const StyledSelectAdult = styled(Button)`
  width: 100%;
  transition: all ease 500ms;
  color: ${({ checked }) => (checked ? "#fff" : "#939393")};
  height: 60px !important;
  border-width: 2px;
  border-radius: 8px !important;
  border-color: #eaeaea;
  padding: 1.2em 1.5em !important;
  &:active {
    border-color: #eaeaea !important;
    color: #939393 !important;
  }
  ${({ checked }) =>
    checked
      ? "color:#fff;border-color: var(--elma-primary) !important;font-weight:600"
      : null}
`
export const FormItem = styled(Form.Item)`
  .ant-form-item-label {
    margin-left: 20px;
    label {
      font-weight: 500;
    }
  }
`
