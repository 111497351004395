import { Col, message, Modal } from "antd"
import React, { useEffect, useState } from "react"
import SearchInputCaisse from "../SearchInput/SearchForfait"
import * as Styled from "./styles"
import TransactionDetails from "./TransactionDetails/TransactionDetails"
import { useAllTransactionsQuery } from "actions/transactions"
import TransactionFilterPeriod from "./PeriodFilter/TransactionFilterPeriod"
import { useSearchParams } from "react-router-dom"
import { Calendar } from "react-feather"
import DatePicker from "components/DatePicker"

const PERIODS = {
  JOUR: { label: "Par jour", value: "DAY" },
  SEMAINE: { label: "Par semaine", value: "WEEK" },
  MOIS: { label: "Par mois", value: "MONTH" },
  ANNEE: { label: "Par année", value: "YEAR" },
  PERSONALISER: {
    label: "Personnalisé",
    value: "CUSTOM",
    icon: <Calendar size={20} />,
  },
}

const CaisseTransactions = () => {
  const [activePeriod, setActivePeriod] = useState(
    localStorage.getItem("periode") ?? PERIODS.JOUR.value,
  )
  // TODO: use customDates for custom dates(Personnaliser)
  const [customDates, setCustomDates] = useState({
    startDate: null,
    endDate: null,
  })
  const [params, setParams] = useState({ period: activePeriod })
  const [searchParams, setSearchParams] = useSearchParams()
  const {
    data: transactions = [],
    isLoading,
    isError,
    isIdle,
  } = useAllTransactionsQuery(params)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const getQueryParams = () => {
    const params = { period: activePeriod }
    if (
      activePeriod === "CUSTOM" &&
      customDates.startDate &&
      customDates.endDate
    ) {
      params.startDate = customDates.startDate.toISOString().split("T")[0]
      params.endDate = customDates.endDate.toISOString().split("T")[0]
    }
    setParams(params)
  }

  useEffect(() => {
    localStorage.setItem("periode", activePeriod)
    // modifier l'url pour avoir ?periode=MONTH afin de garder ButtonSwitch active par defaut et pour l'api
    searchParams.set("periode", activePeriod)
    setSearchParams(searchParams)
    if (activePeriod === "CUSTOM") {
      setIsModalOpen(true) // Open modal if "CUSTOM" is selected
    }
  }, [activePeriod])

  useEffect(() => {
    getQueryParams()
  }, [activePeriod])

  const handleDateChange = (field, date) => {
    setCustomDates((prevDates) => {
      const newDates = { ...prevDates, [field]: date }

      // Validation: La date de debut ne doit pas depasser la date de fin
      if (newDates.startDate && newDates.endDate) {
        if (newDates.startDate > newDates.endDate) {
          message.error(
            "La date de début ne peut pas être après la date de fin.",
          )
          return prevDates // Prevent updating dates if invalid
        }
      }
      return newDates
    })
  }

  const handleOk = () => {
    setIsModalOpen(false)
    getQueryParams() // Refresh params with custom dates
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    setActivePeriod(PERIODS.JOUR.value) // Revert to default if cancelled
  }

  return (
    <>
      <Styled.TransactionsFilterContainer>
        <Styled.TransactionMainContent align={"middle"}>
          <Col span={16}>
            <Styled.Flex>
              {Object.values(PERIODS).map((period) => (
                <Styled.ButtonSwitch
                  key={period.value}
                  active={activePeriod === period.value}
                  onClick={() => setActivePeriod(period.value)}
                  style={{
                    backgroundColor:
                      activePeriod === period.value
                        ? "var(--elma-primary)"
                        : undefined,
                  }}>
                  {period.label} {period?.icon}
                </Styled.ButtonSwitch>
              ))}
            </Styled.Flex>
            {/* <TransactionFilterPeriod /> */}
          </Col>
          <Col span={8}>
            <SearchInputCaisse placeholder={"Rechercher par client"} />
          </Col>
        </Styled.TransactionMainContent>
      </Styled.TransactionsFilterContainer>

      <Modal
        title='Sélectionnez une période personnalisée'
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}>
        <DatePicker
          placeholder='Date de début'
          onChange={(date) => handleDateChange("startDate", date)}
          style={{ width: "100%", marginBottom: "10px" }}
        />
        <DatePicker
          placeholder='Date de fin'
          onChange={(date) => handleDateChange("endDate", date)}
          style={{ width: "100%" }}
        />
      </Modal>

      <div style={{ marginTop: 20 }}>
        <TransactionDetails
          transactions={transactions}
          isLoading={isLoading}
          isError={isError}
          isIdle={isIdle}
          activePeriod={activePeriod}
          customDates={customDates}
        />
      </div>
    </>
  )
}

export default CaisseTransactions
