import styled from "styled-components"
import { Button } from "antd"
import { elmaBreakpoints } from "styles/theme"
export const FilterPeriodContainer = styled.div`
  width: 100%;
  min-height: 65px;
  padding: 20px;
  background-color: #fff;
  box-shadow: var(--elma-shadow--9);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 8px;
  @media screen and (max-width: ${elmaBreakpoints.md}px) {
    justify-content: center;
  }
`
export const Actions = styled.div`
  margin-left: auto;
  @media screen and (max-width: 644px) {
    margin-left: unset;
    margin-top: 10px;
  }
`
export const ButtonAction = styled(Button)`
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
`
