import React from 'react'
import * as Styled from "../styles"
import { Space } from 'antd'
import useSalonCurrency from 'hooks/useSalonCurrency'

const ServicePopulaireCardItem = ({ service, description, onClick, removeSelectedService }) => {
    const currency = useSalonCurrency()
    return (
        <Styled.ServiceItemContainer
            onClick={() => !service?.selected ? onClick(service) : removeSelectedService(service.id)}
            active={service?.selected}
        >
                <Space style={{ justifyContent: "space-between", height: "100%" }} direction='vertical'>
                    <div>
                    <Styled.ServiceTitle>{service.name}</Styled.ServiceTitle>
                    {description(service.duration)}
                    </div>
                <Styled.ServicePrice>{service.price} {currency}</Styled.ServicePrice>
                </Space>
        </Styled.ServiceItemContainer>
    )
}

export default ServicePopulaireCardItem