export const PERIODS_FILTER = {
  MONTH: "month",
  WEEK: "week",
  YEAR: "year",
}
export const DEFAULT_PERIOD = PERIODS_FILTER.MONTH
export const OPTIONS_PERIOD_FILTER = [
  {
    key: PERIODS_FILTER.WEEK,
    text: "Semaine",
  },
  {
    key: PERIODS_FILTER.MONTH,
    text: "Mois",
  },
  {
    key: PERIODS_FILTER.YEAR,
    text: "Année",
  },
]

export const DATE_FILTER_FORMATS = (val) => {
  switch (val) {
    case PERIODS_FILTER.MONTH:
      return "MMMM, YYYY"
    case PERIODS_FILTER.YEAR:
      return "YYYY"
    case PERIODS_FILTER.WEEK:
      return "ww, YYYY"
    default:
      return ""
  }
}
