import React from "react"
import { ArrowLeft } from "react-feather"
import { Link } from "react-router-dom"
import { Button } from "antd"
import PropTypes from "prop-types"

const BackButtonLink = ({ children, to = "/" }) => {
  return (
    <Link to={to} style={{ marginBottom: "2rem", display: "inline-block" }}>
      <Button
        style={{
          padding: 0,
          display: "flex",
          textTransform: "uppercase",
          fontWeight: "bold",
          color: "var(--elma-primary)"
        }}
        type='link'
        icon={<ArrowLeft style={{ marginRight: "9px" }} />}>
        {children}
      </Button>
    </Link>
  )
}

BackButtonLink.propTypes = {
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default BackButtonLink
