import useSalonCurrency from 'hooks/useSalonCurrency'
import React from 'react'
import * as Styled from "../DashboardSalonRevenue/styles"

import Header from "components/HeaderWithIcon"
import TurnOverItem from './TurnOverItem'

const GlobalTurnOver = ({ earning: data }) => {
    const currency = useSalonCurrency()
    return (
        <Styled.Container id='dashboard-turnover'>
            <Header>CHIFFRE D'AFFAIRE</Header>
            <Styled.Content>
                <Styled.Flex>
                    <Styled.Revenue>
                        <Styled.Title level={1}>{data.ca_gobal}</Styled.Title>
                        <Styled.SubTitle>{currency}</Styled.SubTitle>
                    </Styled.Revenue>
                </Styled.Flex>
                <Styled.ComparisonValue>
                    {/* {+data.current > +data.prev ? "+" : "-"}&nbsp;
                    {getPercentegeDiff(data.current, data.prev)}%&nbsp;
                    {comparisonText[state.dashboardPeriod]} */}
                </Styled.ComparisonValue>
                <Styled.Actions>
                    {
                        Number(data.ca_ventes_directes) > Number(data.ca_rv) ? <>
                            <TurnOverItem
                                label={"Ventes directes"}
                                color={"var(--elma-primary)"}
                                globalTurnOver={data.ca_gobal}
                                turnOver={data.ca_ventes_directes}
                            />
                            <TurnOverItem
                                label={"Rendez-vous"}
                                color={"#FF671A"}
                                globalTurnOver={data.ca_gobal}
                                turnOver={data.ca_rv}
                            />
                        </>
                            :
                            <>
                                <TurnOverItem
                                    label={"Rendez-vous"}
                                    color={"var(--elma-primary)"}
                                    globalTurnOver={data.ca_gobal}
                                    turnOver={data.ca_rv}
                                />
                                <TurnOverItem
                                    label={"Ventes directes"}
                                    color={"#FF671A"}
                                    globalTurnOver={data.ca_gobal}
                                    turnOver={data.ca_ventes_directes}
                                />
                            </>
                    }

                </Styled.Actions>
            </Styled.Content>
        </Styled.Container>
    )
}

export default GlobalTurnOver