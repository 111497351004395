import useClientId from "hooks/useClientId"
import useSalonId from "hooks/useSalonId"
import { useQuery, useMutation, useQueryClient } from "react-query"
import clientsAPI from "./api"
import { message } from "antd"
import elmaErrorMessages from "enums/errorMessages"

/**
 * fetches clients
 * @param {string} keyword - keyword to search clients with
 */
export const useClientsQuery = (keyword) => {
  const salonId = useSalonId()

  return useQuery(
    // we list salonId/keyword as deps here
    // in case they change we want to refetch
    ["clients", salonId, keyword],
    () => clientsAPI.getAll(salonId, keyword),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: true,
    }
  )
}
/**
 * fetches clients
 */
export const useOneClientQuery = () => {
  const salonId = useSalonId()
  const clientId = useClientId()

  return useQuery(
    ["client", salonId, clientId],
    () => clientsAPI.getOne(salonId, clientId),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: true,
      onError: (data) => {
        if (data?.message === elmaErrorMessages.client_not_found) {
          message.error("Client(e) n'existe pas.")
        } else {
          message.error(`
      Échec du chargement des données`)
        }
      },
    }
  )
}

/**
 * creates new client
 */
export const useAddClientMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(clientsAPI.add, {
    onSuccess: () => {
      message.success("Client(e) creer avec succès.")

      // Refetch all active queries that begin with `clients` in the key
      queryClient.refetchQueries("clients", { active: true })
    },
    onError: ({ response: { data } = {} }) => {
      if (data?.message === elmaErrorMessages.already_exists) {
        message.error("Client(e) existe déjà.")
      } else {
        message.error("Creation du client(e) a echoué.")
      }
    },
  })
}

/**
 * creates new client
 */
export const useUpdateClientMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(clientsAPI.edit, {
    onSuccess: () => {
      message.success("Client(e) modifié(e) avec succès.")

      // Refetch all active queries that begin with `clients` in the key
      queryClient.refetchQueries("clients", { active: true })
      queryClient.refetchQueries("client", { active: true })
    },
    onError: ({ response: { data } = {} }) => {
      message.error("Modification du client(e) a echoué.")
    },
  })
}