import { FormikProvider, useFormik } from "formik"
import { PAIEMENT_TYPE_STATUS } from 'enums/constants';

export const VenteRapidePaiementProvider = ({ children, onSubmit = () => { }, initialValues }) => {
    const formik = useFormik({
        initialValues: { ..._initialValues, ...initialValues },
        // validationSchema,
        onSubmit,
        enableReinitialize: true,
        validateOnMount: false,
        validateOnChange: false,
        validateOnBlur: false,
    })
    return <FormikProvider value={formik}>{children}</FormikProvider>
}
const _initialValues = {
    facture: {
        mode_paiement: PAIEMENT_TYPE_STATUS.ESPECE,
    },
    currentFacture: {}
}

// const useInitialValues = (initialValues) => {
//     return
// }