import { Col, Row } from 'antd'
import React from 'react'

import * as Styled from "./styles"
import AppLayout from 'components/Layout'
import StatPeriodFilter from 'components/Statistiques/PeriodFilter/StatPeriodFilter'
import { StatistiquesProvider, useStatistiqueContext } from 'contexts/Statistiques/StatistiquesProvider'
import SalonKpis from '../../components/Statistiques/Kpis/SalonKpis'
import { SkeletonCard } from 'components/Dashboard/utils'
import { DashboardProvider } from 'contexts/Dashboard'
import GlobalTurnOver from 'components/Dashboard/DashboardSalonCA'
import { useStatistiques } from 'actions/statistiques'
import {
    Revenue as SalonRevenueChart,
} from "components/Charts"
import {
    DashboardEmployeesRevenue as EmployeesRevenue,
} from "components/Dashboard"

const StatistiqueContainer = () => {
    const { statistique } = useStatistiqueContext()
    const { data, isError, isLoading } = useStatistiques({ params: statistique })
    console.log({ data });
    return <>
        <DashboardProvider>
            <AppLayout.Header title={"Mon Tableau de bord"} />
            <AppLayout.Content scrollAble>
                <StatPeriodFilter />
                <SalonKpis data={data} isError={isError} isLoading={isLoading} />
                <Row style={{ width: "100%", margin: "50px 0" }}>
                    <Styled.Space className='ant-col ant-col-xs-24 ant-col-md-12 ant-col-xl-10'>
                        {isLoading ? (
                            <SkeletonCard />
                        ) : (
                                data && <GlobalTurnOver earning={data} />
                        )}
                    </Styled.Space>
                    <Col id='dashboard-chart' xs={24} md={12} xl={14}>
                        {isLoading ? (
                            <SkeletonCard />
                        ) : (
                            <SalonRevenueChart periodRevenue={[]} />
                        )}
                    </Col>
                </Row>
                <Row gutter={[20, 20]}>
                    <Col xs={24} md={12}>
                        <EmployeesRevenue
                            title={"CA - RDV"}
                            employeesEarnings={data?.employeesEarningsRdv}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <EmployeesRevenue
                            title={"CA - Ventes directes"}
                            employeesEarnings={data?.employeesEarningsVentesDirectes}
                        />
                    </Col>
                </Row>
            </AppLayout.Content>
        </DashboardProvider>
    </>
}

const StatistiquePage = () => {
    return (
        <StatistiquesProvider>
            <StatistiqueContainer />
        </StatistiquesProvider>
    )
}

export default StatistiquePage