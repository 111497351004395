import styled from "styled-components"
import { Typography } from "antd"
export const Header = styled.div`
  display: flex;
  padding: 20px 36px;
  border-bottom: solid 2px #f8f8f8;
  align-items: center;
  svg {
    margin-right: 1.1em;
  }
  span {
    font-size: 18px;
    color: #282828;
    font-weight: bolder;
    text-transform: uppercase;
  }
`
export const Revenue = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`
export const Content = styled.div`
  padding: 26px 36px;
`
export const Container = styled.div`
  background-color: #fff;
  border: solid 2px var(--elma-border--1);
  width: 100%;
  height: 100%;
  border-radius: 8px;
`
export const Flex = styled.div`
  display: flex;
`
export const Title = styled(Typography.Title)`
  font-weight: bolder !important;
  font-size: 64px !important;
  color: #4f4f4f !important;
  margin-bottom: 0 !important;
  @media screen and (max-width: 876px) {
    font-size: 52px !important;
  }
`
export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const SubTitle = styled.span`
  font-weight: 500;
  font-size: 25px;
  color: #bdbdbd;
  margin-left: 3px;
  @media screen and (min-width: 1106px) {
    margin-top: 20px;
  }
  @media screen and (max-width: 576px) {
    font-size: 20px;
    margin-top: 20px;
  }
`
export const ComparisonValue = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #282828;
  margin-bottom: 25px;
`

export const Tax = styled.div`
  border-radius: 100px;
  border: solid 2px;
  border-color: ${({ color }) => color};
  padding: 10px 25px;
  margin-bottom: 10px;
  color: ${({ color }) => color};
  letter-spacing: 1.25px;
  font-weight: 500;
  font-size: 12px;
  width: 100%;
`
export const CA = styled(Typography.Text)`
  font-size: 18px;
  font-weight: 600;
`
