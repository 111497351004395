import salonsAPI from "actions/salons/api"
import { AUTH_STATUS, useAuthContext } from "contexts/Authentication"
import useEmployeesPrefetch from "hooks/useEmployeesPrefetch"
import FullPageSpinner from "pages/FullPageSpinner"
import React from "react"
import "antd-country-phone-input/dist/index.css"
import { useQuery } from "react-query"
import AuthenticatedApp from "routes/authenticated-app"
import UnauthenticatedApp from "routes/unauthenticated-app"
import GlobalStyle from "styles/globalStyles"
import { Route, Routes } from "react-router"
import CheckAuthPage from "pages/CheckAuth/CheckAuthPage"
import NotFound from "components/NotFound/NotFound"

import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

const App = () => {
  const { authenticated, status: authStatus } = useAuthContext()
  // the reason we fetch this here, is because we have
  // to route the whole application under the current salons
  // since some users may belong to multiple salons
  const { status } = useQuery(["salons"], salonsAPI.getAll, {
    refetchOnWindowFocus: false,
    enabled: authenticated,
  })

  useEmployeesPrefetch()

  if (
    authStatus === AUTH_STATUS.IDLE ||
    authStatus === AUTH_STATUS.PENDING ||
    status === "loading"
  ) {
    return <FullPageSpinner />
  }

  return authenticated ? (
    <>
      <GlobalStyle />
      <Routes>
        <Route path="/check-auth" element={<CheckAuthPage />} />
        <Route path="/" element={<AuthenticatedApp />} />
        <Route path="/salons/:salonId/*" element={<AuthenticatedApp />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  ) : (
      <UnauthenticatedApp />
      // <div>Unauthenticated</div>
  )
}

export default React.memo(App)
