import { Radio } from 'antd'
import { paiementOptions } from 'enums/constants'
import React from 'react'
import * as Styled from '../../../styles/index'
import { useFormikContext } from 'formik'

const PaiementOptions = () => {
    const { setFieldValue, values } = useFormikContext()

    const onChange = (e) => {
        setFieldValue("facture", { ...values.facture, mode_paiement: e.target.value });
    };
    return (
        <Radio.Group onChange={onChange} value={values.facture.mode_paiement}>
            {
                paiementOptions.map(paiementOption => {
                    return <Styled.RadioStyle
                        name='mode_paiement'
                        key={paiementOption.id}
                        value={paiementOption.id}
                    >
                        {paiementOption.img ?
                            <img style={{ width: 50, height: 50, borderRadius: 200 }}
                                src={paiementOption.img}
                                alt={paiementOption.title}
                            />
                            : paiementOption.title}
                    </Styled.RadioStyle>
                })
            }
        </Radio.Group>
    )
}

export default PaiementOptions