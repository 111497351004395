import styled from "styled-components"
import { Typography } from "antd"

export const CardTitle = styled(Typography.Title)`
  color: #fff !important;
  margin: 0 !important;
  font-size: 64px !important;
  font-weight: 300 !important;
  line-height: 56.88px !important;
`
export const CardContent = styled.div`
  color: #fff;
  min-width: 40%;
  padding: 1.9em 2.2em;
  width: 100%;
  border-radius: 8px;
  margin-right: 1.5em;
  @media screen and (max-width: 768px) {
    margin-bottom: 1.6em;
  }

  * {
    color: #fff;
  }
`
export const Block = styled.div`
  display: block;
  span {
    &:first-child {
      font-size: 18px;
    }
    &:last-child {
      font-size: 12px;
    }
  }
`
export const Icon = styled.div`
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  width: 35px;
  height: 35px;
  align-items: center;
  align-self: flex-start;
  margin-left: auto;
`
export const Flex = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.2em;
`
