// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n", ""]);
// Exports
exports.locals = {
	"elmaPrimary": "#65376a",
	"elmaPrimaryLight": "#a27ec6",
	"elmaPrimaryLighter": "#f3ca7f",
	"elmaSecondary": "#240041",
	"elmaSecondaryLight": "#45007d",
	"elmaSecondaryLighter": "#dfcfec",
	"elmaText": "#4a494a",
	"elmaTextLight": "#707090",
	"elmaTextLighter": "#707070",
	"elmaLightBackground": "#eaedff",
	"elmaSpacing1": "19px"
};
module.exports = exports;
