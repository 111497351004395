import { salonStorage } from "utils"
import request from "utils/request"

export const getAll = (params) => {
  return request({
    url: `backoffice/salons`,
    params: params,
  })
}

const fetchSalon = (setLoadingSalon, setSalon) => {
  setLoadingSalon(true)
  const token = localStorage.getItem("token")
  fetch(process.env.REACT_APP_BASE_API_URL + "backoffice/salons", {
    headers: {
      Authorization: "Bearer " + token
    }
  }).then((res) => {
    return res.json()
  }).then(response => {
    salonStorage.persist(response[0])
    setSalon(response[0])
    return response
  }).finally(() => {
    setLoadingSalon(false)
  })
}

const salonsAPI = {
  getAll,
  fetchSalon
}

export default salonsAPI
