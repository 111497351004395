import { matchPath, useLocation } from "react-router"

const useClientId = () => {
  const location = useLocation()
  const match = matchPath({
    path: "/salons/:salonId/clients/:clientId",
  }, location.pathname)
  return +match?.params.clientId || null
}

export default useClientId
