import React from "react"
import Chart from "chart.js/auto"
import UserBlock from "components/ServiceBlock/ServiceBlock"

const App = () => {
  const canvasRef = React.createRef(null)

  React.useEffect(() => {
    const ChartComp = () => {
      Chart.defaults.global.defaultFontFamily = "'Poppins', sans-serif"
      new Chart(canvasRef.current, {
        type: "horizontalBar",
        data: {
          labels: ["Katarina", "Neeko", "Ashe"],
          datasets: [
            {
              label: "katarina",
              data: [70, 20, 10],
              backgroundColor: ["#308AE0", "#27AE60", "#FFC059"],
              barThickness: 5,
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                gridLines: {
                  color: "#FFF",
                  zeroLineColor: "#F2F2F2",
                  zeroLineWidth: 2,
                },
                ticks: {
                  display: false,
                },
                scaleLabel: {
                  display: true,
                  labelString: "Categories",
                },
              },
            ],
            xAxes: [
              {
                position: "top",
                ticks: {
                  max: 100,
                  min: 0,
                },
                gridLines: {
                  color: "#F2F2F2",
                  zeroLineColor: "#F2F2F2",
                  zeroLineWidth: 1,
                },

                scaleLabel: {
                  display: true,
                  labelString: "Spendings",
                },
              },
            ],
          },
          tooltips: {
            displayColors: false,
            callbacks: {
              title: function (tooltipItem) {
                return ""
              },
              label: function (tooltipItem) {
                return tooltipItem.label + " : " + tooltipItem.value + "%"
              },
            },
          },
        },
      })
    }
    ChartComp()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      style={{
        backgroundColor: "#fff",
        padding: "23px 15px 15px 15px",
        borderRadius: "8px",
        border: "solid 2px var(--elma-border--1)",
      }}>
      <canvas
        ref={canvasRef}
        style={{
          marginBottom: "10px",
        }}></canvas>
      <UserBlock name={"Katarina"} percentage={"70.00%"} color={"#308AE0"} />
      <UserBlock name={"Neeko"} percentage={"20.00%"} color={"#27AE60"} />
      <UserBlock name={"Ashe"} percentage={"10.00%"} color={"#FFC059"} />
    </div>
  )
}

export default App
