import { Result, Row } from 'antd'
import AppLayout from 'components/Layout'
import React from 'react'
import * as Styled from "./styles/index"
import { useCaisseCurrentStatus } from 'contexts/Caisse'
import { CAISSE_STATUS } from 'enums/constants'
import NouvelEncaissement from '../../components/Caisse/NouvelEncaissement/NouvelEncaissement'
import CaisseTransactions from '../../components/Caisse/Transactions/CaisseTransactions'
import JourneeDeCaisse from '../../components/Caisse/JourneeDeCaisse/JourneeDeCaisse'
import StatusNavigation from '../../components/Caisse/StatusNavigation/StatusNavigation'
import { Route, Routes } from 'react-router'
import PaimentVenteRapide from './PaiementVenteRapide/PaimentVenteRapide';
import { CAISSE_VENTE_RAPIDE_PAIEMENT_PATH, VENTE_RAPIDE_PAIEMENT_RECU_PATH } from 'routes/utils'
import RecuTransaction from './RecuTransaction/RecuTransaction'

const CaisseContainer = () => {
    return <>
        <AppLayout.Header title={"Caisse"} />
        <AppLayout.Content scrollAble>
            <Styled.CaisseContainer>
                <Row>
                    <StatusNavigation />
                </Row>
                <CaisseStatus />
            </Styled.CaisseContainer>
        </AppLayout.Content>
    </>
}

const CaisseStatus = () => {
    const { currentStatus } = useCaisseCurrentStatus()

    return <>
        {
            currentStatus === CAISSE_STATUS['NOUVEL ENCAISSEMENT'] ?
                <NouvelEncaissement /> :
                currentStatus === CAISSE_STATUS.TRANSACTIONS ?
                    <CaisseTransactions /> :
                    currentStatus === CAISSE_STATUS['JOURNEE DE CAISSE'] ?
                        <JourneeDeCaisse /> :
                        <Result status={"error"} title="Pas de statut correspondant" />
        }
    </>
}

const CaissePage = () => {
    return (
        <>
            <Routes>
                <Route path={""} element={<CaisseContainer />} />
                <Route path={VENTE_RAPIDE_PAIEMENT_RECU_PATH()} element={<RecuTransaction />} />
                <Route path={CAISSE_VENTE_RAPIDE_PAIEMENT_PATH} element={<PaimentVenteRapide />} />
            </Routes>
        </>
    )
}

export default CaissePage