import { Avatar, Col, Row, Typography } from 'antd'
import React, { useState } from 'react'
import * as Styled from "../styles"
import useSalonCurrency from 'hooks/useSalonCurrency'
import { convertMinutesTohours, getUserInitials } from 'utils'
import { EditOutlined } from '@ant-design/icons'
import SelectEmploye from './SelectEmploye'
import { useServicesContext } from 'contexts/Services/ServicesProvider'
import { SERVICES_REDUCER_ACTION_TYPE } from 'contexts/Services/reducer'

const PanierCardItem = ({ service }) => {
    const currency = useSalonCurrency()
    const [openSelectEmploye, setOpenSelectEmploye] = useState(false)
    const { dispatchServices } = useServicesContext()

    const changeEmploye = (employe) => {
        dispatchServices({
            type: SERVICES_REDUCER_ACTION_TYPE.CHANGE_SERVICE_EMPLOYEE,
            payload: { employe, service }
        })
        return setOpenSelectEmploye(false)
    }

    return (
        <Styled.ServiceItem>
            <Row justify={"space-between"} >
                <Col span={16}>
                    <Styled.ServiceName>{service.name} ( {convertMinutesTohours(service.duration)} )</Styled.ServiceName>
                </Col>
                <Col span={8}>
                    <Styled.ServicePrice>{service.price} {currency}</Styled.ServicePrice>
                </Col>
            </Row>
            <Row justify={"space-between"} style={{ marginTop: 5 }}>
                <Col span={14}>
                    <Avatar>{getUserInitials(service.selectedEmploye)}</Avatar>
                    <Styled.EmployeeName>{service.selectedEmploye.first_name} {service.selectedEmploye?.last_name}</Styled.EmployeeName>
                </Col>
                <Col span={10} style={{ textAlign: "right" }}>
                    <Styled.Editbtn onClick={() => setOpenSelectEmploye(!openSelectEmploye)}>
                        <EditOutlined style={{ color: "#000", fontSize: 15 }} />
                        {/* <Typography.Text>Modifier</Typography.Text> */}
                    </Styled.Editbtn>
                </Col>
            </Row>
            {
                openSelectEmploye && <SelectEmploye changeEmploye={(employe) => changeEmploye(employe)} employees={service.employee} selectedEmploye={service.selectedEmploye} />
            }
        </Styled.ServiceItem>
    )
}

export default PanierCardItem