import styled from "styled-components"
import { Typography } from "antd"
import { elmaBreakpoints } from "styles/theme"
export const HeaderTitle = styled(Typography.Title)`
  margin-bottom: 0 !important;
  font-weight: bolder !important;
  color: #fff !important;
`
export const Container = styled.div`
  border-radius: 8px;
  margin-top: 1.5em;
  width: 100%;
  height: 100px;
  background-color: var(--elma-primary);
  display: flex;
  @media screen and (max-width: ${elmaBreakpoints.xs}px) {
    padding: 0 1.7em;
  }
  padding-left: 1.7em;
  align-items: center;
  overflow: hidden;
`
export const Decoration = styled.div`
  margin-left: auto;
  @media screen and (min-width: ${elmaBreakpoints.md}px) and (max-width: 880px) {
    display: none;
  }
  @media screen and (max-width: ${elmaBreakpoints.xs}px) {
    display: none;
  }
`
