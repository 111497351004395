import { useTheme } from "styled-components"
// import colors from "./export.less"

export const elmaBreakpoints = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
}


// export const elmaTheme = {
//   colors: {
//     primary: {
//       main: colors.elmaPrimary,
//       light: colors.elmaPrimaryLight,
//       lighter: colors.elmaPrimaryLighter,
//     },
//     secondary: {
//       main: colors.elmaSecondary,
//       light: colors.elmaSecondaryLight,
//       lighter: colors.elmaSecondaryLighter,
//     },
//     semantic: {},
//   },
//   text: {},
//   breakpoints: elmaBreakpoints,
//   sizes: {
//     headerHeight: "calc(50px + 2 * 0.75rem)",
//   },
// }
export const elmaTheme = {
  colors: {
    primary: {
      main: "#65376a",
      light: "#a27ec6",
      lighter: "#f3ca7f",
    },
    secondary: {
      main: "#240041",
      light: "#45007d",
      lighter: "#dfcfec",
    },
    semantic: {},
  },
  text: {},
  breakpoints: elmaBreakpoints,
  sizes: {
    headerHeight: "calc(50px + 2 * 0.75rem)",
  },
}

/**
 * @returns {elmaTheme}
 */
export const useElmaTheme = () => useTheme()
