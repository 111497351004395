import React from 'react'
import * as Styled from "../styles"
import NavigateBack from './NavigateBack'
import SearchClient from './SearchClient'
import AddNewClientModal from './AddNewClientModal'
import ClientsList from './ClientsList'
import EnterFromLeft from 'components/AnimatedMount/EnterFromLeft'

const SelectClientModal = ({ isOpen, onClose }) => {
    return (
        <EnterFromLeft>
            <Styled.SelectContainer isOpen={isOpen}>
                <NavigateBack onClose={onClose} />
                <SearchClient />
                <AddNewClientModal />
                <ClientsList onClose={onClose} />
            </Styled.SelectContainer>
        </EnterFromLeft>
    )
}

export default SelectClientModal