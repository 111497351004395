import React from 'react'
import { Search } from 'react-feather'
import * as Styled from "../styles"
import { useServicesContext } from 'contexts/Services/ServicesProvider'
import { SERVICES_REDUCER_ACTION_TYPE } from 'contexts/Services/reducer'

const SearchInputCaisse = ({ placeholder }) => {
    const { servicesState, dispatchServices } = useServicesContext()
    return (
        <Styled.FormSearch>
            <Styled.InputSearch
                defaultValue={servicesState.serviceSearch}
                value={servicesState.serviceSearch}
                onChange={(e) => {
                dispatchServices({
                    type: SERVICES_REDUCER_ACTION_TYPE.SEARCH_SERVICE,
                    payload: e.target.value.toLowerCase()
                })
            }} size="large" placeholder={placeholder} prefix={<Search size={20} />} />
        </Styled.FormSearch>
    )
}

export default SearchInputCaisse