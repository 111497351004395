import { FormikProvider, useFormik } from "formik"
import _ from "lodash"

//! this is used to host Recu details for displaying
export const RecuPaiementProvider = ({ children, recuPaiement = {}, navigateBack, navigateText }) => {
    const formik = useFormik({
        initialValues: { ...useRecuPaiementInitialValue(recuPaiement), navigateBack, navigateText },
        enableReinitialize: true,
        validateOnMount: false,
        validateOnChange: false,
        validateOnBlur: false,
    })
    return <FormikProvider value={formik}>{children}</FormikProvider>
}
const _initialValues = {
    currentFacture: {}
}

const useRecuPaiementInitialValue = (recuPaiement) => {
    return !_.isEmpty(recuPaiement) ? { currentFacture: recuPaiement } : _initialValues
}