import { useQuery } from "react-query"
import recuPaiement from "./api"
import { useRecuPaiementId } from "hooks/useRecuPaiementId"
import useSalonId from "hooks/useSalonId"
import { useVenteId } from "hooks/useVenteId"

export const useRecuPaiementQuery = () => {
    const appointmenId = useRecuPaiementId()

    const salonId = useSalonId()
    return useQuery(["recuPaiement", appointmenId],
        () => recuPaiement.get({ salonId, appointmenId }),
        {
            refetchOnWindowFocus: true,
            refetchOnMount: true,
        })
}
export const useRecuPaiementVenteRapideQuery = () => {
    const venteId = useVenteId()

    const salonId = useSalonId()
    return useQuery(["recuPaiement", venteId],
        () => recuPaiement.getVente({ salonId, venteId }),
        {
            refetchOnWindowFocus: true,
            refetchOnMount: true,
        })
}
export const useRecuTransactionQuery = () => {
    const venteId = useVenteId()
    const salonId = useSalonId()
    return useQuery(["recuPaiement", venteId],
        () => recuPaiement.getFactureTransaction({ salonId, venteId }),
        {
            refetchOnWindowFocus: true,
            refetchOnMount: true,
        })
}