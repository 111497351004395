import { createContext, useState } from "react";

export const PaiementContext = createContext()

export const PaiementContextProvider = ({ children }) => {
    const [currentIndexPaiement, setCurrentIndexPaiement] = useState(0)
    const goback = () => {
        setCurrentIndexPaiement(prev => prev - 1)
    }
    const goToNext = () => {
        setCurrentIndexPaiement(prev => prev + 1)
    }
    const resetCurrentIndex = () => {
        setCurrentIndexPaiement(0)
    }

    const value = {
        currentIndexPaiement,
        setCurrentIndexPaiement,
        goback,
        goToNext,
        resetCurrentIndex
    }

    return <PaiementContext.Provider value={value}>{children}</PaiementContext.Provider>
}