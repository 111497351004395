import { Avatar, Col, Row } from 'antd'
import React from 'react'
import { getUserInitials } from 'utils'
import * as Styled from "../styles"
import { CloseOutlined } from '@ant-design/icons'

const ClientListItem = ({ client, onClick, resetClient }) => {
    return (
        <Styled.ClientListItem selected={resetClient} justify={"space-between"} align={"middle"} gutter={8} onClick={() => !resetClient ? onClick(client) : null}>
            <Col>
                <Row align={"middle"} gutter={8}>
                    <Col>
                        <Avatar>
                            {
                                getUserInitials(client)
                            }
                        </Avatar>
                    </Col>
                    <Col>
                        <Styled.ClientName>{client.firstName} {client?.lastName}</Styled.ClientName>
                        <Styled.ClientPhone>+{client?.phone_object?.code} {client?.phone_object?.phone}</Styled.ClientPhone>
                    </Col>
                </Row>
            </Col>
            {
                resetClient && <Styled.ResetSelectedClient onClick={resetClient}>
                    <CloseOutlined size={20} />
                </Styled.ResetSelectedClient>
            }            
        </Styled.ClientListItem>
    )
}

export default ClientListItem