import styled from "styled-components"
import { Link, NavLink } from "react-router-dom"
import { elmaBreakpoints } from "styles/theme"

export const leftNavbar = styled.div`
  position: absolute;
  top: 0;
  width: var(--nav-width);
  background-color: @elma-primary;
  padding: 1.25rem 1rem 0 0;
  transition: 0.5s;
  z-index: 100;
  left: ${(props) => (props.show ? "-5px" : "")};
  @media screen and (max-width: ${elmaBreakpoints.sm}px) {
    left: ${(props) => (!props.show ? "0" : "-30%")};
    display: ${(props) => (!props.show ? "initial" : "none")};
    position: fixed;
  }
`
export const Nav = styled.nav`
  padding-left: 5px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`
export const NavLogo = styled(Link)`
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
  padding: 0.5rem 0 0.5rem 1.5rem;
  margin-bottom: 2rem;
  cursor: pointer;
  padding-left: 1.9rem;
`

export const NavName = styled.span`
  color: rgba(255, 255, 255, 0.6);
  transition: 0.5s;
  display: ${(props) => (props.show ? "block" : "none")};
  &:hover {
    color: rgba(255, 255, 255, 1);
  }
`

export const NavLinkk = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
  padding: 0.5rem 0 0.5rem 1.5rem;
  position: relative;
  color: var(--elma-primary-light);
  margin-bottom: 1.5rem;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    color: white;
  }
`

export const NavLinkRouter = styled(NavLink)`
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
  padding: 0.5rem 0 0.5rem 25px;
  position: relative;
  color: var(--elma-primary-light);
  margin-bottom: 1.5rem;
  transition: 0.3s;
  &:hover {
    color: white;
  }
  &.active svg {
    background-color: #863e8d;
    border-radius: 4px;
    color: white;
  }

  &.active span {
    color: white;
  }
`

export const StyledIcon = styled.svg`
  padding: 5px;
  color: rgba(255, 255, 255, 0.6);
  &:hover {
    color: rgba(255, 255, 255, 1);
  }
`
