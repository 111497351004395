import React from "react"
import * as Styled from "components/FilterPeriod"
import { Space } from "antd"
import { SelectContainer, Option } from "components/SelectDropDown"
import moment from "moment"
import DatePicker from "components/DatePicker"
import { Context } from "contexts/Dashboard"
import { OPTIONS_PERIOD_FILTER, DATE_FILTER_FORMATS } from "./utils"
const DashboardFilter = () => {
  const [state, dispatch] = React.useContext(Context)
  const defaultValue = state.dashboardPeriod
  const [picker, setPicker] = React.useState(defaultValue)
  const handleChange = (value) => {
    dispatch({
      type: "SET_DASHBOARD_PERIOD",
      payload: value,
    })
    setPicker(value)
  }
  const handleDatePicker = (date) => {
    dispatch({
      type: "SET_DASHBOARD_DATE",
      payload: moment(date).format("DD-MM-YYYY"),
    })
  }
  const disableFutureDates = (current) => {
    return current && current > moment().endOf("day")
  }
  return (
    <Styled.FilterPeriodContainer>
      <Space size='middle'>
        <DatePicker
          allowClear={false}
          defaultValue={moment()}
          format={DATE_FILTER_FORMATS(defaultValue)}
          picker={picker}
          disabledDate={disableFutureDates}
          onChange={handleDatePicker}
        />
        <SelectContainer
          defaultValue={defaultValue}
          onChange={(val) => handleChange(val)}
          placeholder='Sort Type'>
          {OPTIONS_PERIOD_FILTER.map((val) => (
            <Option key={val.key} value={val.key}>{val.text}</Option>
          ))}
        </SelectContainer>
        {/* <select  onChange={(val) => handleChange(val)}>
          {OPTIONS_PERIOD_FILTER.map((val) => (
            <Option key={val.key} value={val.key}>{val.text}</Option>
          ))}
        </select> */}
      </Space>
    </Styled.FilterPeriodContainer>
  )
}

export default DashboardFilter
