import request from "utils/request"
import { serialize } from "object-to-formdata"
const getAll = (salonId) => {
  if (!salonId) {
    throw new Error("No salonId was provided")
  }
  return request({
    url: `backoffice/salons/${salonId}/employees`,
  })
}

const getOne = (salonId, employeeId) => {
  if (!salonId) {
    throw new Error("No salonId was provided")
  }
  return request({
    url: `backoffice/salons/${salonId}/employees/${employeeId}`,
  })
}
const add = ({ salonId, employee }) => {
  if (!salonId) {
    throw new Error("No salonId was provided")
  }
  const formData = serialize(employee)
  formData.delete("employee[detail][avatar][preview]")
  formData.delete("employee[detail][avatar][file]")
  if (employee.employee.detail.avatar.file) {
    formData.append(
      "employee[detail][avatar]",
      employee.employee.detail.avatar.file
    )
  }
  return request({
    url: `backoffice/salons/${salonId}/employees/new`,
    headers: { "content-type": "application/x-www-form-urlencoded" },
    data: formData,
    method: "POST",
  })
}
const edit = ({ salonId, id: employeeId, employee }) => {
  if (!employee) {
    throw new Error("No employee was provided")
  }
  const formData = serialize(employee)
  formData.delete("employee[detail][avatar][preview]")
  formData.delete("employee[detail][avatar][file]")
  if (employee.employee.detail.avatar.file) {
    formData.append(
      "employee[detail][avatar]",
      employee.employee.detail.avatar.file
    )
  }
  return request({
    url: `backoffice/salons/${salonId}/employees/${employeeId}`,
    headers: { "content-type": "application/x-www-form-urlencoded" },
    data: formData,
    method: "PATCH",
  })
}
const remove = ({ salonId, employeeId }) => {
  if (!salonId) {
    throw new Error("No salonId was provided")
  }
  // new: salons/:id/archiver_employee/:employee_id
  // old: url: `backoffice/salons/${salonId}/employees/${employeeId}`,
  return request({
    url: `backoffice/salons/${salonId}/archiver_employee/${employeeId}`,
    method: "PATCH",
  })
}
const resetPass = (data) => {
  return request({
    url: `backoffice/employees/password/set`,
    method: "POST",
    data: {
      user: data,
    },
  })
}
const requestToken = ({ salonId, employeeId, resetPagePath }) => {
  if (!salonId) {
    throw new Error("No salonId was provided")
  }
  return request({
    url: `backoffice/salons/${salonId}/employees/${employeeId}/password/reset`,
    method: "POST",
    data: {
      user: {
        url: resetPagePath,
      },
    },
  })
}
const employeesAPI = {
  getAll,
  add,
  getOne,
  resetPass,
  requestToken,
  remove,
  edit,
}

export default employeesAPI
