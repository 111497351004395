import React from "react"
import { Typography } from "antd"
import { UserOutlined } from "@ant-design/icons"
import * as Styled from "./styles"
import useSalonCurrency from "hooks/useSalonCurrency"
import { getUsername } from "utils"
const { Text } = Typography

const Card = ({ user }) => {
  const currency = useSalonCurrency()
  return (
    <Styled.Container>
      <Styled.AvatarContainer>
        <Styled.Avatar>
          {getUsername(user).split(" ")[1] === "anonyme" ? (
            <UserOutlined />
          ) : (
            getUsername(user)[0]?.toUpperCase()
          )}
        </Styled.Avatar>
      </Styled.AvatarContainer>
      <Styled.Info>
        <Styled.Heading title={getUsername(user)} level={5}>
          {getUsername(user)}
        </Styled.Heading>
        <Text style={{ fontSize: "13px", color: "#828282" }}>
          {user.total}
          {currency}
        </Text>
      </Styled.Info>
    </Styled.Container>
  )
}
export default Card
