import request from "utils/request"
/**
 *
 * @param {string|number} salonId
 * @param {number} appointmenId - recu info
 */
const get = ({ salonId, appointmenId }) => {
    if (!appointmenId) {
        throw new Error("No appointmenId was provided")
    }
    return request({
        url: `backoffice/salons/${salonId}/getFactureByAppointment/${appointmenId}`,
        method: "GET",
    })
}

const getVente = ({ salonId, venteId }) => {
    if (!venteId) {
        throw new Error("No venteId was provided")
    }
    return request({
        url: `backoffice/salons/${salonId}/getFactureByVente/${venteId}`,
        method: "GET",
    })
}

const getFactureTransaction = ({ salonId, venteId }) => {
    if (!venteId) {
        throw new Error("No venteId was provided")
    }
    return request({
        url: `backoffice/salons/${salonId}/factures/${venteId}`,
        method: "GET",
    })
}

const recuPaiement = {
    get,
    getVente,
    getFactureTransaction
}
export default recuPaiement