import { Space } from 'antd'
import React from 'react'
import * as Styled from "../../styles/index"

const ExpeditionCardItem = ({ label, name }) => {
    return (
        <Space style={{ width: "100%", justifyContent: "space-between", marginTop: 20 }}>
            <Styled.ExpeditionText>
                {label}
            </Styled.ExpeditionText>
            <Styled.ExpeditionText>
                {name ?? ''}
            </Styled.ExpeditionText>
        </Space>
    )
}

export default ExpeditionCardItem