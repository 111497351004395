import { Col, Row, Typography } from "antd"
import styled from "styled-components"
import { elmaBreakpoints } from "styles/theme"

export const TransactionsFilterContainer = styled.div`
  background-color: #fff;
  padding: 10px;
  border-radius: 11px;
  border: 1.32px solid #ddd;
  position: sticky !important;
  top: 0px !important;
  z-index: 100;
`
export const TransactionMainContent = styled(Row)`
  background-color: #f7f8f9;
  padding: 10px;
  border-radius: 10px;
`
export const Flex = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`
export const ButtonSwitch = styled.button`
  all: unset;
  border-radius: 4.462px;
  background: ${({ active }) => (active ? "var(--elma-primary)" : "#DADADA")};
  color: ${({ active }) => (active ? "#fff" : "#434445")};
  padding: 5px 10px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
`
export const TransactionDetailsContainer = styled.div`
  border-radius: 11px;
  border: 1.323px solid #ddd;
  background: #fff;
  padding: 20px;
`
export const TransactionDate = styled(Typography.Paragraph)`
  color: #707070;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26.334px;
  padding: 10px 0px;
`
export const TransactionType = styled(Typography.Text)`
  color: #707070;
  font-size: 17.019px;
  font-style: normal;
  font-weight: 400;
  line-height: 26.334px; /* 138.462% */
`
export const TransactionAmount = styled(Typography.Text)`
  color: #121212;
  text-align: right;
  font-size: 17.019px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.334px; /* 138.462% */
`
export const TransactionCardItem = styled(Col)`
  // border-bottom: 2px dashed #E8EAED;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #eee;
  }
`
export const TransactionCardItemContaint = styled(Row)`
  border-bottom: 2px dashed #e8eaed;
  padding: 10px;
  &:hover {
    // background-color: #eee
  }
`
export const Badge = styled.span`
  border: 2px solid #82b440;
  padding: 5px;
  font-size: 10px;
  font-weight: 500;
  border-radius: 8px;
  font-size: 12px;
  color: #82b440;
`
export const FilterPeriodContainerStyled = styled.div`
  width: 100%;
  margin: 10px 0px;
  padding: 0px;
  //   background-color: #fff;
  //   box-shadow: var(--elma-shadow--9);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 8px;
  @media screen and (max-width: ${elmaBreakpoints.md}px) {
    justify-content: center;
  }
`
export const FormFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
`

export const ImagePaiement = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 100px;
  box-shadow: -4px 4px 41px 8px rgba(0, 0, 0, 0.18);
  margin-left: 5px;
`
