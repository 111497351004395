import { createReducerCtx } from "contexts/utils"
import "./reducer"
import reducer, {
  // eslint-disable-next-line no-unused-vars
  AppointmentFiltersState,
  initialState,
} from "./reducer"

const [useCtx, useDispatch, Provider] = createReducerCtx(reducer, initialState)

/**
 * @callback UseApptFilters
 * @return {AppointmentFiltersState}
 */

/** @type {UseApptFilters}  */
export const useApptFilters = useCtx

export const useApptFiltersDispatch = useDispatch

export const AppointmentFiltersProvider = Provider

export { actionTypes as filtersActiontypes } from "./reducer"
