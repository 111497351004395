import React, { useMemo } from 'react'
import * as Styled from "./styles"
// import { BackButtonLink } from 'components/BackButtonLink';
import { MainPaiementOption } from '../../features/paiementProcedure/components/Paiement/Paiement';
import { VenteRapidePaiementProvider } from './Provider';
import VenteRapidePaiementRecap from './PaiementRecap/VenteRapidePaiementRecap';
import { useVenteRapideMutation } from 'actions/venteDirect';
import { useServicesContext } from 'contexts/Services/ServicesProvider';
import { PAIEMENT_TYPE_STATUS } from 'enums/constants';
import useSalonId from 'hooks/useSalonId';
import { useNavigate } from 'react-router';
import { SERVICES_REDUCER_ACTION_TYPE } from 'contexts/Services/reducer';

const VenteRapidePaiement = () => {
    const { addVenteRapide } = useVenteRapideMutation()
    const salonId = useSalonId()
    const { servicesState, dispatchServices } = useServicesContext()
    const navigate = useNavigate()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const services = useMemo(() => servicesState.panier.map(service => ({ employee_id: service.selectedEmploye.id, service_id: service.id })), [])

    const _initialValues = useMemo(() => ({
        direct_sale: {
            client_id: servicesState.selectedClient.id,
            remise: servicesState.remise,
            mode_paiement: PAIEMENT_TYPE_STATUS.ESPECE,
            services
        },
        totalPaiement: servicesState.panier.reduce((acc, currentService) => acc + Number(currentService.price), 0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [])
    // const 
    const onSubmit = (values, { setSubmitting }) => {

        addVenteRapide({
            salonId,
            data: {
                direct_sale: {
                    ...values.direct_sale,
                    remise: values.direct_sale.remise === null ? 0 : values.direct_sale.remise,
                    mode_paiement: values.facture.mode_paiement
                }
            }
        })
            .then((res) => {
                // console.log({ res });
                dispatchServices({
                    type: SERVICES_REDUCER_ACTION_TYPE.RESET_VENTE_RAPIDE_SETVICES
                })
                navigate(`recu-rapide-paiement/${res.id}`)
                // setTimeout(() => {
                //     navigate(-1)
                // }, 100)
            })
            .finally(() => {
            setSubmitting(false)
        })
    }
    return (
        <VenteRapidePaiementProvider onSubmit={onSubmit} initialValues={_initialValues}>
            <Styled.Container>
                <Styled.PaiementMain>
                    {/* <BackButtonLink to={null}>
                        Procéder au paiement
                    </BackButtonLink> */}
                    <Styled.PaiementRow>
                        <Styled.LeftSide span={12}>
                            <MainPaiementOption />
                        </Styled.LeftSide>
                        <Styled.RightSide span={12}>
                            <VenteRapidePaiementRecap />
                        </Styled.RightSide>
                    </Styled.PaiementRow>
                </Styled.PaiementMain>
            </Styled.Container>
        </VenteRapidePaiementProvider>
    )
}

export default VenteRapidePaiement