import { Row, Typography } from "antd";
import styled, { keyframes } from "styled-components";

export const EmptyMessage = styled(Typography.Text)`
    color: #555;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20.327px; 
`
export const ServiceItem = styled.div`
    border-top: 2.647px dashed #DDD;
    margin-top: 8px;
    padding: 10px;
`
export const ServiceName = styled(Typography.Paragraph)`
    color: #555;
    font-size: 12px;
    // font-style: italic;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 3px !important;
    letter-spacing: 1px;
    max-width: 150px;
`
export const ServicePrice = styled(Typography.Paragraph)`
    color: #0A0A0A;
    font-size: 15.28px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
    text-align: end;
`
export const EmployeeName = styled(Typography.Text)`
    display: inline-block;
    margin-left: 5px;
    color: #000;
    font-size: 13px;
    font-style: italic;
    font-weight: 300;
    line-height: normal;
`
export const Editbtn = styled.button`
    display: inline-flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 11px;
    border: 1px solid #DDD;
    font-size: 12px;
    cursor: pointer;
`
export const TotalPanier = styled(Row)`
    margin-top: 50px;
`
export const TotalLabel = styled(Typography.Text)`
    color: #555;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20.327px;
`
export const TotalPrice = styled(Typography.Text)`
    color: #0A0A0A;
    text-align: right;
    font-size: 15.28px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
`
export const FwTotalPrice = styled(Typography.Title)`
    display: inline-block;
    margin-top: 15.28px;
    color: #121212;
    text-align: right;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 35.112px;
`
const breatheAnimation = keyframes`
 0% { height: 0;opacity: 0, overflow: hidden}
 30% { height: 100px; opacity: .5,overflow: hidden}
 40% { height: 200px; opacity: .7,overflow: hidden}
 100% { height: 300px; opacity:1,overflow: auto}
`
export const EmployeCardItemContainer = styled.div`
    margin-top: 15px;
    background-color: #fff;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 10px;
    position: relative;

    &::before{
        content: "";
        // width: 20px;
        // height: 20px;
        border-left: 10px solid transparent;
        border-top: 20px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 15px solid #DADDE2;
        position: absolute;
        top: -5px;
        right: 20px;
        z-index: 999;
        margin-top: -30px;
    }


`
export const EmployeCardItemMainContain = styled.div`
    padding: 10px;
    height: 300px;
    overflow: auto;
    animation-name: ${breatheAnimation};
    animation-duration: 300ms;
    animation-iteration-count: 1;
`
export const EmployeCardItem = styled(Typography.Paragraph)`
    color: ${({ active }) => active ? "#ffffff" : "#000000"};
    background-color: ${({ active }) => active ? "#A4A4A4" : "#FFFFFF"};
    border-bottom: 1px dashed #ddd;
    padding: 8px;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    transition: all 200ms ease-in-out;

    &:hover{
        background-color: ${({ active }) => active ? "#A4A4A4" : "rgba(0,0,0,0.1)"};
    }
`