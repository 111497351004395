/**
 * This will contain error messages sent from
 * the backend, to map to more meaningful error
 * messages.
 */
const elmaErrorMessages = {
  already_exists: "already_exists",
  invalid_token: "token is not valid",
  client_not_found: "Request failed with status code 404",
  email_taken: "has already been taken",
}

export default elmaErrorMessages
