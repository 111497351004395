import dayjs from "dayjs"

/**
 * @enum
 */
export const DATE_FILTER_PERIODS = {
  ONE_DAY: "ONE_DAY",
  THREE_DAYS: "THREE_DAYS",
  FIVE_DAYS: "FIVE_DAYS",
}

/**
 * @readonly
 */
export const DATE_FILTER_OPTIONS = [
  {
    key: DATE_FILTER_PERIODS.ONE_DAY,
    text: "Un jour",
  },
  {
    key: DATE_FILTER_PERIODS.THREE_DAYS,
    text: "Trois jours",
  },
  {
    key: DATE_FILTER_PERIODS.FIVE_DAYS,
    text: "Cinq jours",
  },
]

export const DATE_FILTER_FORMAT = "DD-MM-YYYY"

export const getDateRange = (period, date) => {
  switch (period) {
    case DATE_FILTER_PERIODS.ONE_DAY:
      return {
        start: dayjs(date).format(DATE_FILTER_FORMAT),
        end: dayjs(date).format(DATE_FILTER_FORMAT),
      }
    case DATE_FILTER_PERIODS.THREE_DAYS:
      return {
        start: dayjs(date).format(DATE_FILTER_FORMAT),
        end: dayjs(date).add(2, "day").format(DATE_FILTER_FORMAT),
      }
    case DATE_FILTER_PERIODS.FIVE_DAYS:
      return {
        start: dayjs(date).format(DATE_FILTER_FORMAT),
        end: dayjs(date).add(4, "day").format(DATE_FILTER_FORMAT),
      }
    default:
      throw Error(`unkown period`)
  }
}
