import request from "utils/request"
import _ from "lodash"

const cancelTransaction = ({ transactionId, data }) => {
    console.log({ data });
    if (!transactionId) throw new Error("transactionId is required in cancelTransaction")
    if (_.isEmpty(data)) throw new Error("data is required in cancelTransaction")
    if (!_.has(data, "fature") && !_.has(data, "facture.motif_annulation")) throw new Error("{fature:{motif_annulation:''}} is required in cancelTransaction")
    return request({
        url: `backoffice/transactions/${transactionId}/annuler`,
        method: "POST",
        data: data
    })
}
/**
 *
 * @param {string|number} salonId
 * @param {object} facture - facture info
 */
const getAll = ({ params, salonId }) => {
    console.log("=========getQueryParams========")
    if (!salonId) {
        throw new Error("No salonId was provided")
    }
    return request({
        url: `backoffice/salons/${salonId}/transactions`,
        method: "GET",
        params
    })
}

const caisseTransactionsAPI = {
    getAll,
    cancelTransaction
}
export default caisseTransactionsAPI