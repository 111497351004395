import React from 'react'
import * as Styled from "../styles"
import { Search } from 'react-feather'
import { useServicesContext } from 'contexts/Services/ServicesProvider'
import { SERVICES_REDUCER_ACTION_TYPE } from 'contexts/Services/reducer'

const SearchClient = () => {
    const { dispatchServices } = useServicesContext()
    return (
        <Styled.FormSearch>
            <Styled.InputSearch onChange={(e) => {
                dispatchServices({
                    type: SERVICES_REDUCER_ACTION_TYPE.CLIENT_SEARCH,
                    payload: e.target.value
                })
            }} size="large" placeholder={"Rechercher par client"} prefix={<Search size={20} />} />
        </Styled.FormSearch>
    )
}

export default SearchClient