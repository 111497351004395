import React from 'react'
import styled from 'styled-components';

// size: 210mm 297mm;
// size: 170mm 220mm;
const PrintStyle = styled.div`
  @media print {
    @page {
      size: 8.5in 14in;
      margin: 0; /* Ajuste les marges à zéro si tu veux utiliser tout l'espace */
    }

    /* Assure que le contenu s'affiche correctement sur la page */
    body, html {
      margin: 0;
      padding: 0;
      zoom: 1; /* Applique un zoom de 90% */
    }

    /* Ajustement du contenu pour éviter un centrage vertical */
    & {
      display: block;
      margin-top: 0;
      padding-top: 0;
    }
  }
`

const PdfPrint = React.forwardRef((props, ref) => {
    // const getPageMargins = () => {
    //     return `
    //     size: Portrait;
    //     margin: 0px !important;
    //     padding: 20px !important;
    //     `;
    // };
    return (
        <PrintStyle ref={ref}>
            {/* <style type="text/css" media="print">
                {getPageMargins()}
            </style> */}
            {props.children}
        </PrintStyle>
    );
});

export default PdfPrint