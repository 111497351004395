import { Form, Typography } from "antd";
import styled from "styled-components";

export const RecapText = styled(Typography.Text)`
    font-weight: 500
`
export const TotalDesc = styled(Typography.Paragraph)`
    font-size: 12px;
    font-weight: 300
`
export const TotalPrice = styled(Typography.Text)`
    font-weight: 600;
    font-size: 20px;
`
export const OldTotalPrice = styled(Typography.Paragraph)`
    font-weight: 500;
    font-size: 16px;
    color: #555;
    text-decoration: line-through;
`
export const FormItem = styled(Form.Item)`
  .ant-form-item-label {
    margin-left: 0px;
    label {
      font-weight: 500;
      width: 100%;
    }
  }
  .ant-slider-handle {
        border: solid 2px var(--elma-primary) !important;
  }
  .ant-slider-track{
        background-color:var(--elma-primary) !important;
  }
`