import { Col, Row } from 'antd'
import useSalonCurrency from 'hooks/useSalonCurrency'
import React from 'react'
import * as Styled from "./styles"
import { useFormikContext } from 'formik'

const TotalPaiement = () => {
    const currency = useSalonCurrency()
    const { values } = useFormikContext()

    return (
        <Row justify={"space-between"}>
            <Col>
                <Styled.RecapText>Total</Styled.RecapText>
                <Styled.TotalDesc>Incluant 0.0 XOF de taxes</Styled.TotalDesc>
            </Col>
            <Col>
                <Styled.TotalPrice>
                    {values.totalPaiement - Number(values.direct_sale.remise)} {currency}
                </Styled.TotalPrice>
                {
                    values.direct_sale.remise && <Styled.OldTotalPrice>
                        {values.totalPaiement} {currency}
                    </Styled.OldTotalPrice>
                }
            </Col>
        </Row>
    )
}

export default TotalPaiement