import React from "react"
import Card from "./card/"
import { Row, Col, Carousel } from "antd"
import SkeletonCard from "../utils/SkeletonCard"
import useMediaQuery from "hooks/useMediaQuery"
import { useElmaTheme } from "styles/theme"
import { APPOINTMENT_STATUS, appointmentStatusOptions } from "enums/constants"
const WeeklyStats = ({ appointments, loading }) => {
  const theme = useElmaTheme()
  const isUnderMD = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`)
  console.log({ appointmentStatusOptions });
  if (isUnderMD) {
    return (
      <Carousel>
        {appointmentStatusOptions.filter(status => status.id !== APPOINTMENT_STATUS.KPI).map((status) => {
          if (loading)
            return (
              <Col className='carousel-items-space'>
                <SkeletonCard />
              </Col>
            )

          return (
            <Col key={status.id} className='carousel-items-space'>
              <Card
                title={status.title}
                color={status.color}
                data={appointments[status.id]}
              />
            </Col>
          )
        })}
      </Carousel>
    )
  }
  return (
    <Row gutter={[16, 16]} id='dashboard-stats'>
      {appointmentStatusOptions.map((status) => {
        if (loading)
          return (
            <Col s={24} md={8}>
              <SkeletonCard />
            </Col>
          )

        return (
          <Col md={8}>
            <Card
              key={status.id}
              title={status.title}
              color={status.color}
              data={appointments[status.id]}
            />
          </Col>
        )
      })}
    </Row>
  )
}
export default WeeklyStats
