import { useFormikContext } from 'formik'
import React from 'react'
import PaiementServices from '../PaimentServices/PaiementServices'
import * as Styled from '../../styles/index'
import { Col, Image, Row, Typography } from 'antd'
import successIcon from "assets/images/success-icon.png"
import useCurrentSalon from 'hooks/useCurrentSalon'

const PrinterHeader = () => {
    const currentSalon = useCurrentSalon()
    return <Row justify={"center"} gutter={20} style={{ textAlign: "center" }}>
        <Col span={24}>
            <Image src={successIcon} width={50} height={50} />
        </Col>
        <Col span={24}>
            <Typography.Title level={4} style={{ margin: "20px 0" }}>Recu paiement - {currentSalon?.name}</Typography.Title>
        </Col>
    </Row>
}

const RecuToPrint = () => {
    const { values } = useFormikContext()

    return (
        <Styled.Printer>
            <PrinterHeader />
            <PaiementServices
                mode_paiement={values.currentFacture.mode_paiement}
                numero_facture={values.currentFacture.numero_facture}
                services={values.currentFacture.services}
                paymentDate={values.currentFacture.date_facture}
                client={values.currentFacture.client}
                montant={values.currentFacture.montant}
            />
        </Styled.Printer>
    )
}

export default RecuToPrint