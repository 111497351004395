import styled from "styled-components"
import { Card, Col, Typography, Avatar } from "antd"
export const ServiceCardContainer = styled(Card)`
  border-radius: 8px;
`
export const ServiceCardCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Count = styled(Avatar)`
  background-color: var(--elma-primary);
  margin-left: 7px;
`
export const Price = styled(Typography.Paragraph)`
  color: var(--elma-primary);
  font-weight: 600;
  font-size: 15px;
  margin: 0 !important;
`
export const Description = styled(Typography.Paragraph)`
  color: #b6b6b6;
`
