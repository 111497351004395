import React from "react"
import frFR from "antd/es/locale/fr_FR"
import { ConfigProvider } from "antd"
import "dayjs/locale/fr"

const frLocale = {
  ...frFR,
  DatePicker: {
    ...frFR.DatePicker,
    lang: {
      shortWeekDays: "di_lu_ma_me_je_ve_sa".split("_"),
      shortMonths: "janv_févr_mars_avr_mai_juin_juil_août_sept_oct_nov_déc".split(
        "_"
      ),
      ...frFR.DatePicker.lang,
    },
  },
}

const AntDProvider = ({ children }) => {
  return (
    <ConfigProvider locale={frLocale} componentSize='middle'>
      {children}
    </ConfigProvider>
  )
}

export default AntDProvider
