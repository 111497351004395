import React from 'react'
import PaiementRow from '../Row/PaiementRow'
import useSalonCurrency from 'hooks/useSalonCurrency'
import { useFormikContext } from 'formik'
import { Divider } from 'antd'

const PaiementServices = () => {
    const { values: { currentFacture: {
        services,
        numero_facture,
        date_facture,
        mode_paiement,
        client,
        // montant_paye,
        montant,
        // montant_a_payer,
        remise,
        type_transaction,
        // appointment: { price }
    }
    }
    } = useFormikContext()
    const currency = useSalonCurrency()

    // console.log({ montant_a_payer, montant_paye, remise });

    return (
        <>

            <PaiementRow title={"Numéro de référence"} description={numero_facture} />
            <PaiementRow title={"Prénom et Nom du client"} description={`${client?.first_name} ${client?.last_name ?? ""}`} />

            <PaiementRow title={"Type Transaction"} description={type_transaction} />
            <PaiementRow
                // hasDevider={false}
                title={"Date du paiement"}
                description={new Intl.DateTimeFormat("fr-Fr", {
                    dateStyle: "long",
                    timeStyle: "short"
                }).format(new Date(date_facture))} />
            {/* <Typography.Title style={{ marginTop: 10 }} mark level={5}>Services</Typography.Title> */}
            {
                services?.map((service, i) => {
                    console.log({ service });

                    return <PaiementRow title={"Service " + (i + 1)} description={service.service.name} />
                })
            }
            {/* <Typography.Title style={{ marginTop: 10 }} mark level={5}>Paiement</Typography.Title> */}
            <PaiementRow title={"Mode de paiement"} description={mode_paiement} />
            <PaiementRow title={"Remise"} description={`${remise ?? "0.0"} ${currency}`} />
            <Divider style={{ borderWidth: "2px" }} variant="dashed" dashed />
            <PaiementRow
                title={"Montant"}
                // description={`${montant_paye ?? montant_paye} ${currency}`}
                montant={`${montant} ${currency}`} />
            <Divider />
        </>
    )
}

export default PaiementServices