import React from 'react'
import { Typography } from 'antd';
import Divider from 'components/Divider';

const TitlePaiement = ({ children }) => {
    return (
        <Typography.Title level={5}>
            {children}
            <Divider />
        </Typography.Title>
    )
}

export default TitlePaiement