import PropTypes from "prop-types"
import { APPOINTMENT_STATUS, USER_SEX } from "./constants"

export const idPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
])

export const AppointmentStatusPropType = PropTypes.oneOf(
  Object.keys(APPOINTMENT_STATUS)
)

export const ServicePropType = {
  current_price: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  gender: PropTypes.oneOf(Object.values(USER_SEX)),
  id: idPropType.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
}

export const EmployeePropType = PropTypes.shape({
  id: idPropType,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  email: PropTypes.string,
  address: PropTypes.string,
  birthdate: PropTypes.string,
  city: PropTypes.string,
  color: PropTypes.string,
  login: PropTypes.string,
  phone: PropTypes.string,
})

export const appointmentPropType = {
  appointmentId: idPropType.isRequired,
  employee: EmployeePropType.isRequired,
  end: PropTypes.string.isRequired,
  start: PropTypes.string.isRequired,
  id: idPropType.isRequired,
  salonId: idPropType.isRequired,
  service: PropTypes.string.isRequired,
  service_id: idPropType.isRequired,
  status: AppointmentStatusPropType.isRequired,
}
