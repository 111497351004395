import React from 'react'
import ServicesPopulaires from 'components/Caisse/ServicesPopulaire/ServicesPopulaires'
import SearchInputCaisse from 'components/Caisse/SearchInput/SearchForfait'

const CaisseVenteRapide = () => {
    return (
        <div>
            {/* <Styled.VenteTitle >Services populaires</Styled.VenteTitle> */}
            <SearchInputCaisse placeholder={"Rechercher des services"} />
            <ServicesPopulaires />
        </div>
    )
}

export default CaisseVenteRapide