import React from "react"
import { LoadingOutlined } from "@ant-design/icons"
import { Spin } from "antd"
const Loading = ({ noMargin }) => {
  return (
    <div style={{ marginTop: !noMargin ? "3rem" : 0, textAlign: "center" }}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </div>
  )
}
export default Loading
