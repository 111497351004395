import request from 'utils/request';

const getStatistiques = ({ salonId, params }) => {
    if (!salonId) throw new Error("salonId is required in getStatistiques")
    return request({
        url: `backoffice/salons/${salonId}/new_dashboard`,
        method: "GET",
        params
    })
}

export const statistiquesApi = {
    getStatistiques
}