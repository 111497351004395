import { Result } from 'antd'
import React from 'react'
// import ServicePopulaireCardItem from '../ServicesPopulaire/ServicePopulaireCardItem'
// import * as Styled from "../styles"
import GridList from 'react-flexible-list'

const ForfaitList = () => {
    return (
        <div style={{ marginTop: 10 }}>
            <GridList
                resourceData={[]}
                resourceItem={() => <p>me</p>}
                cardWidth={200}
                emptyComponent={() => <Result status={"warning"} title="Pas de forfaits" />}
            />
        </div>
    )
}

export default ForfaitList