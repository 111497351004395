import useSalonId from "hooks/useSalonId"
import { useMutation, useQuery } from "react-query"
import caisseTransactionsAPI from "./api"
import { message } from "antd"

/**
 * fetche All transactions
 */
export const useAllTransactionsQuery = (params) => {
    
    const salonId = useSalonId()
    return useQuery(
        ["AllTransactions", params],
        () => caisseTransactionsAPI.getAll({ params, salonId }), {
        fetchPolicy: "no-cache",
        // refetchInterval: 60000,
        refetchOnMount: true,
        refetchOnReconnect: true
    }
    )
}

export const useCancelTransactionMutation = () => {
    const { mutateAsync: cancelTransaction, isLoading, isIdle, isError, isSuccess } = useMutation(caisseTransactionsAPI.cancelTransaction, {
        onSuccess: () => {
            message.success("Transaction annulée")
        },
        onError: (error) => {
            console.log({ error });
            message.error("Une erreur s'est produite")
        }
    })

    return { cancelTransaction, isLoading, isIdle, isError, isSuccess }
}