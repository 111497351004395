import React, { lazy } from "react"
import { Routes, Route } from "react-router-dom"
import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"
import CaissePage from "pages/Caisse"
import StatistiquePage from "pages/Statistiques"

// this delay is to prevent flashing
// i.e when route loads too fast and the animation
// looks as if it flashed ==> bad UI
const DELAY = 350
const DashboardPage = loadable(() =>
  pMinDelay(
    import(/* webpackChunkName: "Login" */ "pages/Dashboard/index.js"),
    DELAY
  )
)
// const DashboardPage = lazy(() => import("pages/Dashboard/index.js"))
const ClientPage = lazy(() => import("pages/Clients"))
const AppointmentsPage = lazy(() => import("pages/Appointments"))
const EditAppointmentPage = lazy(() => import("pages/Appointments/Edit"))
const AddAppointmentPage = lazy(() => import("pages/Appointments/New"))
const PlayGround = lazy(() => import("pages/PlayGround"))
const Categories = lazy(() => import("pages/Categories"))

const Packs = lazy(() => import("pages/Packs"))

const Employees = lazy(() => import("pages/Employee"))

const SalonSettings = lazy(() => import("pages/SalonSettings"))
const SalonAgenda = lazy(() => import("pages/Agenda"))

/**
 * nested routes shouldn't have a starting "/"
 * it's added below.
 */
const routes = [
  {
    component: StatistiquePage,
    path: "",
    exact: true,
  },
  {
    component: ClientPage,
    path: "clients/*",
  },
  {
    component: CaissePage,
    path: "caisse/*",
    exact: true,
  },
  {
    component: AppointmentsPage,
    path: "appointments/*",
    exact: true,
  },
  {
    component: SalonAgenda,
    path: "agenda/",
    exact: true,
  },
  {
    component: PlayGround,
    path: "playground/",
    exact: true,
  },
  {
    component: EditAppointmentPage,
    path: "appointments/:appointmentId/edit",
    exact: true,
  },
  {
    component: Categories,
    path: "categories/*",
  },
  {
    component: Packs,
    path: "packs",
  },
  {
    component: Employees,
    path: "employees/*",
  },
  {
    component: AddAppointmentPage,
    path: "appointments/new",
    exact: true,
  },
  {
    component: SalonSettings,
    path: "settings",
    exact: true,
  },

]

const AuthNestedRoutes = () => {
  // const { path: rootPath } = useMatch()
  return (
    <>
      <Routes>
        {routes.map(({ path, component: Component }) => {
          // console.log({ path });
          return <Route key={path} path={`${path}`} element={<Component />} />
        })}
        {/* <Route path={""} element={<DashboardPage />} />
        <Route path={"me"} element={() => <h1>Me here</h1>} /> */}
      </Routes>
    </>
  )
}

// AuthNestedRoutes.propTypes = {
//   redirectTo: PropTypes.string,
// }

export default AuthNestedRoutes
