import React, { useMemo, useState } from "react"
import * as Styled from "../styles"
import {
  Button,
  Col,
  Divider,
  Image,
  Result,
  Row,
  Space,
  Typography,
  message,
} from "antd"
import Loading from "components/Loading"
// import TransactionCardItem from './TransactionCardItem'
import { FixedSizeList as List } from "react-window"
import useSalonCurrency from "hooks/useSalonCurrency"
import moneyImage from "assets/images/money-bill.png"
import { VENTE_RAPIDE_PAIEMENT_RECU_PATH } from "routes/utils"
import { useNavigate } from "react-router"
import { useCancelTransactionMutation } from "actions/transactions"
import MotifModal from "./MotifModal"
import {
  TRANSACTION_STATUS,
  TRANSFER_ARGENT_IMAGE,
  TRANSFER_ARGENT_TYPE,
} from "enums/constants"
import moment from "moment"

const TransactionDetails = ({
  transactions = [],
  isLoading,
  isError,
  isIdle,
  customDates,
  activePeriod,
}) => {
  const {
    cancelTransaction,
    isError: isErroCanceling,
    isLoading: isCancelingTransaction,
  } = useCancelTransactionMutation()
  const [motif, setMotif] = useState("")
  const [selectedTransactionId, setSelectedTransactionId] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const navigate = useNavigate()
  const currency = useSalonCurrency()

  const paymentTotals = useMemo(() => {
    return transactions.reduce(
      (totals, transaction) => {
        const amount = parseFloat(transaction.montant) || 0
        const paymentType = transaction.mode_paiement || "OTHER"

        if (!totals[paymentType]) {
          totals[paymentType] = 0
        }
        totals[paymentType] += amount
        totals.total += amount

        return totals
      },
      { OM: 0, WAVE: 0, CARTE_BANCAIRE: 0, ESPECE: 0, OTHER: 0, total: 0 },
    )
  }, [transactions])

  console.log(activePeriod)

  // Determine dynamic date range for display
  const formattedDateRange = useMemo(() => {
    switch (activePeriod) {
      case "YEAR":
        return `${moment().format("YYYY")}`
      case "MONTH":
        return `${moment().format("MMMM YYYY")}`
      case "WEEK":
        return `Du ${moment().startOf("week").format("D MMMM")} au ${moment()
          .endOf("week")
          .format("D MMMM YYYY")}`
      case "DAY":
        return moment().format("D MMMM YYYY")
      case "CUSTOM":
        if (customDates.startDate && customDates.endDate) {
          return `Du ${customDates.startDate.format(
            "D MMMM YYYY",
          )} au ${customDates.endDate.format("D MMMM YYYY")}`
        }
        return "Période personnalisée non définie"
      default:
        return "Période non définie"
    }
  }, [activePeriod, customDates])

  if (isLoading || isIdle) {
    return <Loading />
  }
  if (isError) {
    return (
      <Result
        status={"error"}
        title="Une erreur s'est produit lors de la récupération des transactions"
      />
    )
  }

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    if (!motif) return message.info("Veuillez fournir le motif d'annulation")
    cancelTransaction({
      transactionId: selectedTransactionId,
      data: { facture: { motif_annulation: motif } },
    }).then((res) => {
      setMotif("")
      setIsModalOpen(false)
    })
    setMotif("")
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const TransactionCardItem = ({ index, key, style }) => (
    <Styled.TransactionCardItemContaint
      align={"middle"}
      justify={"space-between"}
      gutter={20}
      key={key}
      style={style}>
      <Styled.TransactionCardItem
        onClick={() =>
          navigate(VENTE_RAPIDE_PAIEMENT_RECU_PATH(transactions[index].id))
        }
        span={20}>
        <Row align={"middle"} justify={"space-between"}>
          <Col span={10}>
            <Space size={10}>
              <Image src={moneyImage} width={37} height={37} />
              <Typography.Text>
                {transactions[index]?.date_facture
                  ? new Intl.DateTimeFormat("fr-Fr", {
                      dateStyle: "full",
                      timeStyle: "medium",
                    }).format(new Date(transactions[index]?.date_facture))
                  : "..."}
              </Typography.Text>
            </Space>
            {/* {transactions[index]?.mode_paiement ?
                            <Typography.Paragraph
                                style={{ border: "1px solid", width: "max-content", padding: "2px 10px", marginTop: 5, borderRadius: 100, fontSize: 12, fontWeight: 500 }}
                            >
                                {transactions[index]?.mode_paiement}
                            </Typography.Paragraph> :
                            null} */}
          </Col>
          <Col span={8}>
            <Styled.Flex size={3}>
              {transactions[index]?.mode_paiement ===
              TRANSFER_ARGENT_TYPE.OM ? (
                <Styled.ImagePaiement
                  style={{
                    width: 20,
                    height: 20,
                    borderRadius: 100,
                    boxShadow: "-4px 4px 41px 8px rgba(0,0,0,0.18)",
                  }}
                  src={TRANSFER_ARGENT_IMAGE.OM}
                  alt={transactions[index]?.mode_paiement}
                />
              ) : transactions[index]?.mode_paiement ===
                TRANSFER_ARGENT_TYPE.WAVE ? (
                <Styled.ImagePaiement
                  src={TRANSFER_ARGENT_IMAGE.WAVE}
                  alt={transactions[index]?.mode_paiement}
                />
              ) : transactions[index]?.mode_paiement ===
                TRANSFER_ARGENT_TYPE.CARTE_BANCAIRE ? (
                <Styled.ImagePaiement
                  src={TRANSFER_ARGENT_IMAGE.CARTE_BANCAIRE}
                  alt={transactions[index]?.mode_paiement}
                />
              ) : transactions[index]?.mode_paiement ? (
                <Typography.Paragraph
                  style={{
                    border: "1px solid",
                    width: "max-content",
                    padding: "2px 10px",
                    margin: 0,
                    borderRadius: 100,
                    fontSize: 12,
                    fontWeight: 500,
                  }}>
                  {transactions[index]?.mode_paiement}
                </Typography.Paragraph>
              ) : null}
              <Styled.Badge>
                {transactions[index]?.etat === "paye" ? "PAYÉ" : "EN ATTENTE"}
              </Styled.Badge>
            </Styled.Flex>
          </Col>
          <Col span={5} style={{ textAlign: "right" }}>
            <Typography.Text>
              {transactions[index]?.montant} {currency}
            </Typography.Text>
          </Col>
        </Row>
      </Styled.TransactionCardItem>
      <Col span={4} style={{ textAlign: "right" }}>
        {transactions[index]?.etat === TRANSACTION_STATUS.ANNULE ? (
          <Typography.Paragraph
            style={{
              textAlign: "right",
              color: "red",
              marginRight: 10,
              // borderBottom: "1px solid red",
              // width: "max-content"
            }}>
            {TRANSACTION_STATUS.ANNULE.toUpperCase()}
          </Typography.Paragraph>
        ) : (
          <Button
            onClick={() => {
              setSelectedTransactionId(transactions[index].id)
              showModal()
            }}
            danger>
            Annuler
          </Button>
        )}
      </Col>
    </Styled.TransactionCardItemContaint>
  )

  return (
    <Row>
      {isModalOpen && (
        <MotifModal
          motif={motif}
          handleMotif={(value) => setMotif(value)}
          isModalOpen={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
        />
      )}

      <Col span={18}>
        <Styled.TransactionDetailsContainer>
          <Styled.TransactionDate>{formattedDateRange}</Styled.TransactionDate>
          <Row justify={"space-between"} style={{ margin: "10px 0" }}>
            <Col>
              <Styled.TransactionType>Carte bancaire</Styled.TransactionType>
            </Col>
            <Col>
              <Styled.TransactionAmount>
                {paymentTotals.CARTE_BANCAIRE} {currency}
              </Styled.TransactionAmount>
            </Col>
          </Row>
          <Row justify={"space-between"} style={{ margin: "10px 0" }}>
            <Col>
              <Styled.TransactionType>OM</Styled.TransactionType>
            </Col>
            <Col>
              <Styled.TransactionAmount>
                {paymentTotals.OM} {currency}
              </Styled.TransactionAmount>
            </Col>
          </Row>
          <Row justify={"space-between"} style={{ margin: "10px 0" }}>
            <Col>
              <Styled.TransactionType>Wave</Styled.TransactionType>
            </Col>
            <Col>
              <Styled.TransactionAmount>
                {paymentTotals.WAVE} {currency}
              </Styled.TransactionAmount>
            </Col>
          </Row>
          <Row justify={"space-between"} style={{ margin: "10px 0" }}>
            <Col>
              <Styled.TransactionType>Espèce</Styled.TransactionType>
            </Col>
            <Col>
              <Styled.TransactionAmount>
                {paymentTotals.ESPECE} {currency}
              </Styled.TransactionAmount>
            </Col>
          </Row>
          <Divider style={{ borderWidth: "1.5px" }} variant='dashed' dashed />
          <Row justify={"space-between"} style={{ margin: "20px 0" }}>
            <Col>
              <Styled.TransactionType>Total</Styled.TransactionType>
            </Col>
            <Col>
              <Styled.TransactionAmount>
                {paymentTotals.total} {currency}
              </Styled.TransactionAmount>
            </Col>
          </Row>
          <List
            height={600}
            itemCount={transactions.length}
            itemSize={100}
            layout='vertical'>
            {TransactionCardItem}
          </List>
        </Styled.TransactionDetailsContainer>
      </Col>
    </Row>
  )
}

export default TransactionDetails
