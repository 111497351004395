import { Col, Divider, Row } from "antd"
import React from "react"
import * as Styled from "../../styles/index"
import { TRANSFER_ARGENT_IMAGE, TRANSFER_ARGENT_TYPE } from "enums/constants"
import { removeUnderscores } from "utils"

const Image = ({ img, description }) => (
  <img
    style={{
      width: 40,
      height: 40,
      borderRadius: 100,
      boxShadow: "-4px 4px 41px 8px rgba(0,0,0,0.18)",
    }}
    src={img}
    alt={description}
  />
)

const modePaiement = {
  OM: {
    img: TRANSFER_ARGENT_IMAGE.OM,
  },
  WAVE: {
    img: TRANSFER_ARGENT_IMAGE.WAVE,
  },
  CARTE_BANCAIRE: {
    img: TRANSFER_ARGENT_IMAGE.CARTE_BANCAIRE,
  },
}

const PaiementRow = ({ title, description, montant }) => {
  // console.log({ description });

  return (
    <Row
      style={{ padding: "9px 0px", alignItems: "center" }}
      justify={"space-between"}>
      <Col>
        <Styled.LabelText>{title}</Styled.LabelText>
      </Col>
      <Col>
        {description === TRANSFER_ARGENT_TYPE.OM ||
        description === TRANSFER_ARGENT_TYPE.WAVE ||
        description === TRANSFER_ARGENT_TYPE.CARTE_BANCAIRE ? (
          <>
            <span style={{ padding: 5 }}>
              {removeUnderscores(description.toLowerCase())}
            </span>
            <Image img={modePaiement[description].img} />
          </>
        ) : (
          <Styled.DescriptionText>{description}</Styled.DescriptionText>
        )}
        {montant && <Styled.MontantText>{montant}</Styled.MontantText>}
        {/* {montant && <Styled.RemiseText>{montant}</Styled.RemiseText>} */}
      </Col>
      {/* {
                hasDevider && <Divider />
            } */}
    </Row>
  )
}

export default PaiementRow
