const Reducer = (state, action) => {
  switch (action.type) {
    case "SET_DASHBOARD_PERIOD":
      return {
        ...state,
        dashboardPeriod: action.payload,
      }
    case "SET_DASHBOARD_DATE":
      return {
        ...state,
        dashboardDate: action.payload,
      }
    case "EMPLOYEES_HEIGHT_UPDATE":
      return {
        ...state,
        employeesHeaderHeight: action.payload,
      }
    default:
      return state
  }
}

export default Reducer
