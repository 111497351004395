import { createContext, useContext, useMemo, useReducer } from "react"
import { statistiquesInitialState, statistiquesReducer } from "./reducer"

const StatistiqueContext = createContext({})

export const StatistiquesProvider = ({ children }) => {
    const [state, dispatch] = useReducer(statistiquesReducer, statistiquesInitialState)
    const value = useMemo(() => {
        return { statistique: state, dispatchStatistiques: dispatch }
    }, [state.period, state.date])

    return <StatistiqueContext.Provider value={value}>{children}</StatistiqueContext.Provider>
}

export const useStatistiqueContext = () => {
    const ctx = useContext(StatistiqueContext)

    if (!ctx) throw new Error("No context provided for statistique")

    return ctx
}