import { Carousel, Col, Result, Row, Typography } from 'antd'
import { SkeletonCard } from 'components/Dashboard/utils'
import StatCardItem from 'components/Statistiques/Card/StatCardItem'
import { STATISTIQUES_STATUS, statistiquesOptions } from 'enums/constants'
import useMediaQuery from 'hooks/useMediaQuery'
import React from 'react'
import GridList from 'react-flexible-list'
import { useElmaTheme } from 'styles/theme'

const SalonKpis = ({ data, isError, isLoading }) => {
    const theme = useElmaTheme()
    const isUnderMD = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`)

    if (isError) return <Result title="Une erreur est survenu lors de la recuperation du CA" status={"error"} />

    if (isUnderMD) {
        return <>
            {
                statistiquesOptions.map((statOption) => {
                    if (isLoading) {
                        return <>
                            <Row>
                                {
                                    statOption.data.map(() => {
                                        return <>
                                            <Col s={24} md={8}>
                                                <SkeletonCard key={statOption} />
                                            </Col>
                                        </>
                                    })
                                }
                            </Row>
                        </>
                    }
                    return <Carousel>
                        {/* <Typography.Title level={3} style={{ margin: "20px 0 0px" }}>{statOption.title}</Typography.Title> */}
                        {
                            statOption.data.map(stat => {
                                return <StatCardItem
                                    label={stat.title}
                                    title={data[stat.id] ?? "0"}
                                    currency={stat.id === STATISTIQUES_STATUS.CA_GLOBAL || stat.id === STATISTIQUES_STATUS.CA_RV || stat.id === STATISTIQUES_STATUS.CA_VENTE_DIRECTES}
                                    bgColor={stat.color}
                                />
                            })
                        }
                    </Carousel>
                })
            }
        </>
    }

    return (
        <div>
            {
                statistiquesOptions.map((statOption) => {
                    if (isLoading) {
                        return <>
                            <Row>
                                {
                                    statOption.data.map(() => {
                                        return <>
                                            <Col s={24} md={8}>
                                                <SkeletonCard key={statOption} />
                                            </Col>
                                        </>
                                    })
                                }
                            </Row>
                        </>
                    }
                    return <>
                        <Typography.Title level={3} style={{ margin: "20px 0 0px" }}>{statOption.title}</Typography.Title>
                        <GridList
                            minHeight={100}
                            resourceData={statOption.data}
                            resourceItem={(stat) => <StatCardItem
                                label={stat.title}
                                title={data[stat.id] ?? "0"}
                                currency={stat.id === STATISTIQUES_STATUS.CA_GLOBAL || stat.id === STATISTIQUES_STATUS.CA_RV || stat.id === STATISTIQUES_STATUS.CA_VENTE_DIRECTES}
                                bgColor={stat.color}
                            />}
                            cardWidth={300}
                        />
                    </>
                })
            }
        </div>
    )
}

export default SalonKpis