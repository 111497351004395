import { Typography } from 'antd'
import React from 'react'
import * as Styled from "./styles"

const EmptyPanier = () => {
    return (
        <div style={{ textAlign: "center", marginTop: "40px", minHeight: "30px", display: "flex", justifyContent: "center", flexDirection: "column" }}>
            <Typography.Paragraph>Le panier est vide</Typography.Paragraph>
            <Styled.EmptyMessage>Sélectionnez les articles que vous souhaitez
                ajouter à votre panier</Styled.EmptyMessage>
        </div>
    )
}

export default EmptyPanier