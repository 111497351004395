import React from "react"
import * as Styled from "./styles"
import { Row, Col, Typography, Tooltip } from "antd"
import useSalonCurrency from "hooks/useSalonCurrency"
const ServiceCard = ({ name, description, count, price, total }) => {
  const currency = useSalonCurrency()
  return (
    <Styled.ServiceCardContainer>
      <Row>
        <Col span={4}>
          {count ? (
            <Tooltip title={`Utilisé ${count} fois`}>
              <Styled.Count size={30}>{count}</Styled.Count>
            </Tooltip>
          ) : (
            <Styled.Count size={30}>N/A</Styled.Count>
          )}
        </Col>
        <Col xs={12} md={20} xl={12}>
          <Typography.Title style={{ margin: 0 }} level={5}>
            {name}
          </Typography.Title>
          <Tooltip title={description}>
            <Styled.Description ellipsis={{ rows: 2, expandable: false }}>
              {description || "N/A"}
            </Styled.Description>
          </Tooltip>
        </Col>
        <Col
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginLeft: "-18px",
          }}
          xs={8}
          md={24}
          xl={8}>
          <Tooltip title={`Prix ${price || total} ${currency}`}>
            <Styled.Price ellipsis={{ rows: 1, expandable: false }}>
              {price || total} {currency}
            </Styled.Price>
          </Tooltip>
        </Col>
      </Row>
    </Styled.ServiceCardContainer>
  )
}
export default ServiceCard
