import React from "react"
import * as Styled from "./styles"
import BackButton from "components/BackButton"
// import { Form, Button, Row, Col, Tooltip, Space } from "antd"
import HeaderForm from "./Header/"
// import { AlertCircle } from "react-feather"
import useMediaQuery from "hooks/useMediaQuery"
import { useElmaTheme } from "styles/theme"
import { getClientHomePath } from "routes/utils"
import useSalonId from "hooks/useSalonId"
import ClientForm from "./ClientAddForm/ClientAddForm"
const ClientCreationForm = ({ isModal, closeModal, onSubmit }) => {

  const salonId = useSalonId()
  const clientHomePath = getClientHomePath(salonId)
  const theme = useElmaTheme()
  const isUnderMD = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`)

  return (
    <Styled.Container>
      {isUnderMD && !isModal ? (
        <BackButton type='primary' to={clientHomePath} />
      ) : (
        ""
      )}
      <HeaderForm title='Créer un(e) client(e)' />
      <ClientForm clientHomePath={clientHomePath} isModal={isModal} closeModal={closeModal} onSubmit={onSubmit} />
      {/* <Form style={{ marginTop: "37px" }} layout='vertical'>
        <Row gutter={16}>
          <Col xs={24} lg={12}>
            <Styled.FormItem
              required
              name='first_name'
              initialValue={initialValues.first_name}
              label='Prénom'>
              <Input
                required
                name='first_name'
                value={values.first_name}
                onChange={handleChange}
                placeholder='Patrick'
              />
              {errors.first_name ? (
                <Tooltip color='var(--elma-danger)' title={errors.first_name}>
                  <Styled.Error>
                    <AlertCircle color='var(--elma-danger)' />
                  </Styled.Error>
                </Tooltip>
              ) : (
                ""
              )}
            </Styled.FormItem>
          </Col>
          <Col xs={24} lg={12}>
            <Styled.FormItem
              initialValue={initialValues.last_name}
              validateStatus={errors.last_name ? "error" : undefined}
              name='last_name'
              label='Nom'>
              <Input
                name='last_name'
                onChange={handleChange}
                value={values.last_name}
                placeholder='Jennings'
              />
              {errors.last_name ? (
                <Tooltip color='var(--elma-danger)' title={errors.last_name}>
                  <Styled.Error>
                    <AlertCircle color='var(--elma-danger)' />
                  </Styled.Error>
                </Tooltip>
              ) : (
                ""
              )}
            </Styled.FormItem>
          </Col>
          <Col xs={24} lg={12}>
            <Styled.FormItem
              name='email'
              validateStatus={errors.email ? "error" : undefined}
              label='Email'>
              <Input
                name='email'
                value={values.email}
                onChange={handleChange}
                placeholder='PatrickIJennings@armyspy.com'
              />
              {errors.email ? (
                <Tooltip color='var(--elma-danger)' title={errors.email}>
                  <Styled.Error>
                    <AlertCircle color='var(--elma-danger)' />
                  </Styled.Error>
                </Tooltip>
              ) : (
                ""
              )}
            </Styled.FormItem>
          </Col>
          <Col xs={24} lg={12}>
            <Styled.FormItem required label='Telephone'>
              <CountryPhoneInput
                value={{
                  phone: values.phone_object.phone
                    ? values.phone_object.phone
                    : values.phone,
                  short: values.phone_object.short
                    ? values.phone_object.short
                    : "SN",
                  code: values.phone_object.code
                    ? values.phone_object.code
                    : "221",
                }}
                defaultValue={{
                  short: "ma",
                }}
                onChange={(value) => {
                  setFieldValue("phone_object.phone", value.phone)
                  setFieldValue("phone_object.short", value.short)
                  setFieldValue("phone_object.code", value.code)
                }}
                locale='fr'
                style={inputStyles}
              />

              {errors.phone_object ? (
                <Tooltip
                  color='var(--elma-danger)'
                  title={errors.phone_object.phone}>
                  <Styled.Error>
                    <AlertCircle color='var(--elma-danger)' />
                  </Styled.Error>
                </Tooltip>
              ) : (
                ""
              )}
            </Styled.FormItem>
          </Col>
          <Col xs={24} lg={12}>
            <Styled.FormItem
              required
              validateStatus={errors.sexe ? "error" : undefined}
              label='Sexe'>
              <Select
                onChange={(value) => {
                  values.sexe = value
                }}
                defaultValue={initialValues.sexe}
                style={{ width: "100%" }}>
                {Object.values(USER_SEX).map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
                {errors.sexe ? (
                  <Tooltip color='var(--elma-danger)' title={errors.sexe}>
                    <Styled.Error>
                      <AlertCircle color='var(--elma-danger)' />
                    </Styled.Error>
                  </Tooltip>
                ) : (
                  ""
                )}
              </Select>
            </Styled.FormItem>
          </Col>
          <Col xs={24} lg={12}>
          </Col>
          <Styled.ActionCol xs={24} lg={12}>
            <Styled.FormItem>
              <Space>
                {!isModal && !isUnderMD ? (
                  <Link to={clientHomePath}>
                    <Button className='elma-btn-custom'>Annuler</Button>
                  </Link>
                ) : (
                  ""
                )}
                {isModal ? (
                  <Button
                    onClick={() => closeModal(false)}
                    className='elma-btn-custom'>
                    Annuler
                  </Button>
                ) : (
                  ""
                )}
                <Button
                  onClick={handleSubmit}
                  type='primary'
                  className='elma-btn-custom'
                  loading={isSubmitting}>
                  Ajouter
                </Button>
              </Space>
            </Styled.FormItem>
          </Styled.ActionCol>
        </Row>
      </Form> */}
    </Styled.Container>
  )
}
export default ClientCreationForm
