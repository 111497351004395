import { useServicesContext } from 'contexts/Services/ServicesProvider'
import React from 'react'
import EmptyPanier from '../EmptyPanier'
import PanierCardItem from './PanierCardItem';
import { Alert, Col, Result, Row } from 'antd';
import TotalPanier from '../TotalPanier/TotalPanier';
import PanierAction from './PanierAction';
import _ from "lodash"

const Panier = () => {
    const { servicesState } = useServicesContext()
    return (
        <div>
            {
                servicesState.panier.length ?
                    <>
                        <Row justify={"space-between"} style={{ marginTop: 20 }}>
                            <Col>Service</Col>
                            <Col>Montant</Col>
                        </Row>
                        {
                            servicesState.panier.map(service => {
                                return <PanierCardItem service={service} />
                            })
                        }
                        {
                            !_.isEmpty(servicesState.selectedClient) ? <>
                                <TotalPanier />
                                <PanierAction />
                            </> : null
                        }
                    </> : <EmptyPanier />
            }
        </div>
    )
}

export default Panier