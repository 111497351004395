import { Button, Result } from 'antd'
import useSalonId from 'hooks/useSalonId'
// import Button from 'components/Button'
import React from 'react'
import { useNavigate } from 'react-router'

const NotFound = () => {
    const navigate = useNavigate()
    const idSalon = useSalonId()
    return (
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={<Button onClick={() => navigate(`/salons/${idSalon}`)} type="link">Back Home</Button>}
        />
    )
}

export default NotFound