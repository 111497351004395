import React from "react"
import PropTypes from "prop-types"
import { Space, Typography } from "antd"
import { ServicePropType } from "enums/propTypes"
import useSalonCurrency from "hooks/useSalonCurrency"
import * as Styled from "./styles"
const ServiceItem = ({ service }) => {
  const currency = useSalonCurrency()
  return (
    <Styled.ServiceItemContainer>
      <div>
        <Typography.Text
          style={{
            display: "block",
            fontWeight: "500",
            textTransform: "uppercase",
          }}>
          {service.name}
        </Typography.Text>
      </div>
      <Space>
        {service.promotionalPrice &&
        +service.promotionalPrice !== +service.price ? (
          <Typography.Text type='secondary' delete>
            {service.price}
            {currency}
          </Typography.Text>
        ) : null}

        <Typography.Text style={{ fontWeight: "500" }}>
          {service.promotionalPrice || service.price}
          {currency}
        </Typography.Text>
      </Space>
    </Styled.ServiceItemContainer>
  )
}

ServiceItem.propTypes = {
  service: PropTypes.shape(ServicePropType).isRequired,
}

export default ServiceItem
