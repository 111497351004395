import React from "react"
import ReactDOM from "react-dom/client"
import "./index.less"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
// import { ReactQueryDevtools } from "react-query-devtools"
import { QueryClient, QueryClientProvider } from "react-query"
import RootProvider from "contexts/root-provider"
import dayjs from "dayjs"
import "dayjs/locale/fr"
import LogRocket from "logrocket"
const isDev = process.env.NODE_ENV === "development"

dayjs.locale("fr")
if (!isDev) LogRocket.init("mqtmzz/elmadeal")

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root'));

if (process.env.NODE_ENV === 'production') {
  console.log = () => { }
}

root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <RootProvider>
        <App />
      </RootProvider>
    </BrowserRouter>
    {/* {isDev && <ReactQueryDevtools initialIsOpen={false} />} */}
  </QueryClientProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
