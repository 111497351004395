import React from 'react'
// import * as Styled from "../styles"
// import { Button, Col, Form, Row, Space, Tooltip } from 'antd'
// import Input from "components/Input"
// import { AlertCircle } from 'react-feather'
// import CountryPhoneInput, { ConfigProvider } from 'antd-country-phone-input';
// import en from 'world_countries_lists/data/countries/en/world.json';
// import { SelectContainer as Select, Option } from "components/SelectDropDown"
// import { TYPE_CLIENT, USER_SEX } from 'enums/constants'
// import useMediaQuery from 'hooks/useMediaQuery'
// import { useElmaTheme } from 'styles/theme'
// import Button from 'components/Button'
import useClientCreateForm from '../useForm'
// import { Link } from 'react-router-dom'
import ClientBaseForm from '../Form'

// import 'antd/dist/antd.css';
// import 'antd-country-phone-input/dist/index.css';

const ClientForm = ({ isModal, closeModal, onSubmit, clientHomePath }) => {
    // const inputStyles = {
    //     borderRadius: "8px",
    //     borderColor: "#eaeaea ",
    //     borderWidth: "2px ",
    //     borderStyle: "solid",
    //     display: "flex",
    //     alignItems: "center",
    //     padding: "9px 20px",
    //     height: "45px",
    // }
    // const theme = useElmaTheme()
    // const isUnderMD = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`)
    const {
        values,
        handleSubmit,
        errors,
        initialValues,
        handleChange,
        isSubmitting,
        setFieldValue,
    } = useClientCreateForm(onSubmit)
    // console.log({ values });
    // const [value, setValue] = useState({ short: 'sn' });

    return (
        <>
            <ClientBaseForm
                isModal={isModal}
                closeModal={closeModal}
                clientHomePath={clientHomePath}
                values={values}
                handleSubmit={handleSubmit}
                errors={errors}
                initialValues={initialValues}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                isSubmitting={isSubmitting}
            />
            {/* <Form style={{ marginTop: "37px" }} layout='vertical'>
                <Row gutter={16}>
                    <Col xs={24} lg={12}>
                        <Styled.FormItem
                            required
                            name='first_name'
                            initialValue={initialValues.first_name}
                            label='Prénom'>
                            <Input
                                required
                                name='first_name'
                                value={values.first_name}
                                onChange={handleChange}
                                placeholder='Patrick'
                            />
                            {errors.first_name ? (
                                <Tooltip color='var(--elma-danger)' title={errors.first_name}>
                                    <Styled.Error>
                                        <AlertCircle color='var(--elma-danger)' />
                                    </Styled.Error>
                                </Tooltip>
                            ) : (
                                ""
                            )}
                        </Styled.FormItem>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Styled.FormItem
                            initialValue={initialValues.last_name}
                            validateStatus={errors.last_name ? "error" : undefined}
                            name='last_name'
                            label='Nom'>
                            <Input
                                name='last_name'
                                onChange={handleChange}
                                value={values.last_name}
                                placeholder='Jennings'
                            />
                            {errors.last_name ? (
                                <Tooltip color='var(--elma-danger)' title={errors.last_name}>
                                    <Styled.Error>
                                        <AlertCircle color='var(--elma-danger)' />
                                    </Styled.Error>
                                </Tooltip>
                            ) : (
                                ""
                            )}
                        </Styled.FormItem>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Styled.FormItem
                            name='email'
                            validateStatus={errors.email ? "error" : undefined}
                            label='Email'>
                            <Input
                                name='email'
                                value={values.email}
                                onChange={handleChange}
                                placeholder='PatrickIJennings@armyspy.com'
                            />
                            {errors.email ? (
                                <Tooltip color='var(--elma-danger)' title={errors.email}>
                                    <Styled.Error>
                                        <AlertCircle color='var(--elma-danger)' />
                                    </Styled.Error>
                                </Tooltip>
                            ) : (
                                ""
                            )}
                        </Styled.FormItem>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Styled.FormItem required label='Telephone'>
                            <ConfigProvider locale={en}>
                                <CountryPhoneInput
                                    value={{
                                        phone: values.phone_object.phone
                                            ? values.phone_object.phone
                                            : values.phone,
                                        short: values.phone_object.short
                                            ? values.phone_object.short
                                            : "SN",
                                        code: values.phone_object.code
                                            ? values.phone_object.code
                                            : "221",
                                    }}
                                    defaultValue={{
                                        short: "ma",
                                    }}
                                    onChange={(value) => {
                                        setFieldValue("phone_object.phone", value.phone)
                                        setFieldValue("phone_object.short", value.short)
                                        setFieldValue("phone_object.code", value.code)
                                    }}
                                    locale='fr'
                                    style={inputStyles}
                                />
                            </ConfigProvider>

                            {errors.phone_object ? (
                                <Tooltip
                                    color='var(--elma-danger)'
                                    title={errors.phone_object.phone}>
                                    <Styled.Error>
                                        <AlertCircle color='var(--elma-danger)' />
                                    </Styled.Error>
                                </Tooltip>
                            ) : (
                                ""
                            )}
                        </Styled.FormItem>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Styled.FormItem
                            required
                            validateStatus={errors.sexe ? "error" : undefined}
                            label='Sexe'>
                            <Select
                                onChange={(value) => {
                                    values.sexe = value
                                }}
                                defaultValue={initialValues.sexe}
                                style={{ width: "100%" }}>
                                {Object.values(USER_SEX).map((item) => (
                                    <Option key={item} value={item}>
                                        {item}
                                    </Option>
                                ))}
                                {errors.sexe ? (
                                    <Tooltip color='var(--elma-danger)' title={errors.sexe}>
                                        <Styled.Error>
                                            <AlertCircle color='var(--elma-danger)' />
                                        </Styled.Error>
                                    </Tooltip>
                                ) : (
                                    ""
                                )}
                            </Select>
                        </Styled.FormItem>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Styled.FormItem
                            required
                            validateStatus={errors.type_client ? "error" : undefined}
                            label='Type client'>
                            <Select
                                onChange={(value) => {
                                    values.type_client = value
                                }}
                                defaultValue={initialValues.type_client}
                                style={{ width: "100%" }}>
                                {Object.values(TYPE_CLIENT).map((item) => (
                                    <Option key={item} value={item}>
                                        {item}
                                    </Option>
                                ))}
                                {errors.sexe ? (
                                    <Tooltip color='var(--elma-danger)' title={errors.type_client}>
                                        <Styled.Error>
                                            <AlertCircle color='var(--elma-danger)' />
                                        </Styled.Error>
                                    </Tooltip>
                                ) : (
                                    ""
                                )}
                            </Select>
                        </Styled.FormItem>
                    </Col>
                    <Styled.ActionCol xs={24} lg={12}>
                        <Styled.FormItem>
                            <Space>
                                {!isModal && !isUnderMD ? (
                                    <Link to={clientHomePath}>
                                        <Button className='elma-btn-custom-cancel'>Annuler</Button>
                                    </Link>
                                ) : (
                                    ""
                                )}
                                {isModal ? (
                                    <Button
                                        onClick={() => closeModal(false)}
                                        className='elma-btn-custom-cancel'>
                                        Annuler bii
                                    </Button>
                                ) : (
                                    ""
                                )}
                                <Button
                                    onClick={handleSubmit}
                                    type='primary'
                                    className='elma-btn-custom'
                                    loading={isSubmitting}>
                                    Ajouter
                                </Button>
                            </Space>
                        </Styled.FormItem>
                    </Styled.ActionCol>
                </Row>
            </Form> */}
        </>
    )
}

export default ClientForm