import styled, { css } from "styled-components"
export const Header = styled.div`
  display: flex;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  ${({ bordered, borderColor }) => {
    if (bordered) {
      return css`
        border: solid 2px ${borderColor};
      `
    } else {
      return css`
        border-bottom: solid 2px ${borderColor};
      `
    }
  }};
  align-items: center;
`
export const Title = styled.div`
  display: flex;
  align-items: center;
  color: #282828;
  font-weight: bolder;
  text-transform: uppercase;
`

export const IconWrapper = styled.span`
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  background-color: #000;
  color: white;
  margin-right: 1.1em;
  color: #fff;

  svg {
    width: 16px;
    height: 16px;
  }
`
