import { Skeleton } from "antd"
import React from "react"
import styled from "styled-components"

const SkeletonCard = () => {
  return (
    <Container>
      <Skeleton
        title={{ width: 100 }}
        paragraph={{
          rows: 2,
        }}
        active
      />
    </Container>
  )
}

const Container = styled.div`
  padding: 0.5rem 0.75rem;
  margin-bottom: 0.5rem;
  background: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
`

export default SkeletonCard
